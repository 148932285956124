import { render, staticRenderFns } from "./AdditionalCosts.vue?vue&type=template&id=252d0fcd&scoped=true&"
import script from "./AdditionalCosts.vue?vue&type=script&lang=js&"
export * from "./AdditionalCosts.vue?vue&type=script&lang=js&"
import style0 from "./AdditionalCosts.vue?vue&type=style&index=0&id=252d0fcd&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "252d0fcd",
  null
  
)

export default component.exports