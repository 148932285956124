<template>
	<div>
		<div class="additional-costs-header">
			<h5>Zusätzliche Kosten</h5>
		</div>
		<div class="additional-costs-wrapper d-flex flex-column">
			<div class="add-costs-wrapper d-flex" v-for="(additionalCost, idx) in additionalCosts" :key="idx">
				<TextInput
					label="Beschreibung"
					:value="additionalCost.description"
					:editable="true"
					:updateFn="updateDescription"
					:updateKey="idx.toString()"
				/>
				<!-- <b-form-textarea
					class="description-input"
					placeholder="Beschreibung"
					rows="1"
					max-rows="6"
					v-model="additionalCost.description"
					@blur="update"
				></b-form-textarea> -->
				<div class="ml-2">
					<PriceInput
						label="Kosten"
						:value="additionalCost.costs"
						:editable="true"
						:updateFn="updateCosts"
						:updateKey="idx.toString()"
					/>
				</div>
				<div class="ml-2">
					<b-button variant="danger" class="shadow-none" size="sm" @click="remove(idx)">
						<feather-icon icon="TrashIcon" />
					</b-button>
				</div>
			</div>
		</div>
		<div class="action-btn-wrapper">
			<b-button variant="primary" class="shadow-none" size="sm" @click="add">+ Kosten hinzufügen</b-button>
		</div>
	</div>
</template>

<script>
import {
	BButton,
	BModal,
	BRow,
	BCol,
	BFormInput,
	BFormDatepicker,
	BFormTextarea,
	BInputGroup,
	BInputGroupAppend,
	BDropdown,
	BDropdownItem,
	BFormSelect,
	BSpinner,
} from 'bootstrap-vue'

import PriceInput from './PriceInput'
import TextInput from './TextInput'

export default {
	name: 'AdditionalCosts',
	components: { PriceInput, TextInput, BFormTextarea, BButton },
	props: {
		config: {
			type: Object,
			required: true,
		},
		productionCosts: {
			type: Object,
			required: true,
		},
		updateFn: {
			type: Function,
			required: true,
		},
		updateKey: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			additionalCosts: [],
		}
	},
	mounted() {
		if (this.config.additionalCosts) {
			this.additionalCosts = this.config.additionalCosts
		}
	},
	methods: {
		add() {
			const tmp = this.additionalCosts
			tmp.push({
				description: '',
				costs: 0,
			})
			this.additionalCosts = tmp
		},
		remove(idx) {
			const tmp = this.additionalCosts
			tmp.splice(idx, 1)
			this.additionalCosts = tmp
			this.update()
		},
		updateDescription(idx, value, cb) {
			this.additionalCosts[idx].description = value
			this.update()
			cb()
		},
		updateCosts(idx, value, cb) {
			this.additionalCosts[idx].costs = value
			this.update()
			cb()
		},
		update() {
			if (this.additionalCosts && this.updateFn) {
				this.updateFn(this.updateKey, this.additionalCosts, () => {})
			}
		},
	},
}
</script>

<style lang="sass" scoped>
.additional-costs-header
    margin-bottom: 12px
    margin-left: 20px
    h5
        margin: 0
        font-size: 22px
.additional-costs-wrapper
    .add-costs-wrapper
        width: 1070px
        margin: 0 0px 20px 20px

.action-btn-wrapper
    margin-left: 20px

.description-input
    border: 2px solid #7367f0
</style>
