<template>
	<div class="mt-1">
		<b-row>
			<b-col cols="12">
				<b-card class="shadow-none">
					<div class="d-flex flex-row justify-content-between config-heading">
						<div class="d-flex align-items-start justify-content-start flex-column">
							<div class="label">Konfiguration</div>
							<div class="number">{{ parseInt(index + 1) }} von {{ confCount }}</div>
						</div>
						<div class="d-flex align-items-start justify-content-start flex-column">
							<div class="label">Textilie</div>
							<div class="number">{{ configuration.name }}</div>
						</div>
						<div class="d-flex align-items-start justify-content-start flex-column">
							<div class="label">Stück</div>
							<div class="number">{{ totalAmount }}</div>
						</div>
						<div class="d-flex align-items-start justify-content-start flex-column">
							<div class="label">Farben</div>
							<div class="number">{{ Object.keys(configuration.variants).length }}</div>
						</div>
						<div class="d-flex align-items-start justify-content-start flex-column">
							<div class="label">Motive</div>
							<div class="number">{{ Object.keys(configuration.finishes).length }}</div>
						</div>
						<div class="d-flex align-items-start justify-content-start flex-column">
							<div class="label">Größen</div>
							<div class="number">{{ configuration.variants[0].sizes.length }}</div>
						</div>
					</div>
				</b-card>
			</b-col>
		</b-row>
		<b-row class="mb-0" v-if="configuration.customProductDescription">
			<b-col cols="12">
				<b-card class="shadow-none">
					<div class="mb-1"><b>Kunden-Beschreibung</b></div>
					<div class="custom-product-description">
						{{ configuration.customProductDescription }}
					</div>
				</b-card>
			</b-col>
		</b-row>
		<b-row class="mb-3" v-for="(variant, variantIdx) in configuration.variants" :key="variantIdx">
			<b-col cols="5">
				<b-card class="shadow-none full-height variant-settings" full-height>
					<TaskCheck :configurationIdx="configurationIdx" :variantIdx="variantIdx" type="textile" />
					<div class="variant-label">Farbvariante {{ parseInt(variantIdx) + 1 }}</div>
					<h3 class="font-weight-light">{{ configuration.name }}, {{ variant.color }}</h3>
					<span class="shop">
						<b-link :href="shopUrl" target="_blank" v-if="configuration.ref.shopName === 'stanley-stella'"
							>{{ configuration.ref.shopName }}, {{ configuration.ref.externalArticleNumber }}</b-link
						>
						<span v-else>{{ configuration.ref.shopName }}, {{ configuration.ref.externalArticleNumber }}</span>
					</span>

					<MockupPreview class="mt-2" :variant="variant" :conf="configuration" :files="requestFiles" />
				</b-card>
			</b-col>
			<b-col cols="7" class="d-flex justify-content-between flex-column">
				<div>
					<b-row v-for="(finish, finishIdx) in configuration.finishes" :key="finishIdx">
						<b-col cols="12">
							<b-card class="shadow-none mb-1 finish-row pt-1 pb-1 pl-1 pr-1" block no-body>
								<TaskCheck
									:configurationIdx="configurationIdx"
									:variantIdx="variantIdx"
									:finishIdx="finishIdx"
									type="motive"
								/>
								<b class="mb-1">Motiv {{ parseInt(finishIdx) + 1 }}</b>
								<b-row no-gutters>
									<b-col cols="6">
										<div class="d-flex flex-column align-items-start">
											<a class="file-download" :href="$getFileUrlById(finish.fileName)" target="_blank">
												<b-img
													:src="$getFileUrlById(finish.fileName)"
													:class="[$getFileUrlById(finish.fileName).match('svg') && 'svg-img']"
												/>
											</a>
											<ChangeFileModal
												:variantFinish="variant.finishes[finishIdx]"
												:variantIdx="variantIdx"
												:finish="finish"
												:finishIdx="finishIdx"
												:files="request.fileIds"
												:configIdx="configurationIdx.toString()"
												:requestId="request._id"
												class="mb-1"
												v-if="request.status === 'PENDING'"
											/>
										</div>
									</b-col>
									<b-col cols="6">
										<div class="finish-settings ml-1 d-flex align-items-start justify-content-start flex-column">
											<span class="technique">{{ resolveLabel('technique', finish.technique) }}</span>
											<span
												>{{ resolveLabel('sizeMeasure', finish.sizeMeasure) }}:
												{{ finish.size[finish.sizeMeasure] }}cm</span
											>
											<span
												>{{ resolveLabel('side', finish.side) }} /
												{{ resolveLabel('position', finish.position) }}</span
											>
										</div>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col cols="12">
										<div class="mt-1 comment-container" v-if="finish.comment">"{{ finish.comment }}"</div>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col cols="12" class="colors-alt pt-2">
										<div
											v-if="
												variant.finishes[finishIdx].type === 'single' ||
												variant.finishes[finishIdx].type === 'multiple'
											"
											class="inner"
										>
											<div
												v-for="(color, colorIdx) in variant.finishes[finishIdx].colors"
												:key="colorIdx"
												class="color d-flex align-items-center justify-content-start"
												:class="[color.substituteRgbColor && 'substitue']"
											>
												<div class="blob" :style="{ background: color.hexColor }"></div>
												<div class="name hex">{{ color.hexColor }}</div>
												<div
													v-if="color.substituteRgbColor"
													class="mx-1 d-flex align-items-start justify-content-start"
												>
													<feather-icon icon="ArrowRightIcon" />
												</div>
												<div
													v-if="color.substituteRgbColor"
													class="blob"
													:style="{ background: color.substituteRgbColor }"
												></div>
												<div v-if="color.substituteRgbColor" class="name">
													<div>{{ color.substituteColor }}</div>
													<div class="yarn-number">{{ color.yarnNumber }}</div>
												</div>
											</div>
										</div>
										<div v-if="variant.finishes[finishIdx].type === 'recommendation'" class="inner">
											<RecommendColorModal
												:variantFinish="variant.finishes[finishIdx]"
												:variantIdx="variantIdx"
												:finish="finish"
												:finishIdx="finishIdx"
												:files="request.files"
												:configIdx="configurationIdx.toString()"
												:requestId="request._id"
												:request="request"
												class="mb-1"
												:disabled="
													request.status === 'OFFER_ACCEPTED_AWAITING_PAYMENT' ||
													request.status === 'AWAITING_CONFIRMATION'
												"
											/>
											<div class="mb-1 comment-container" v-if="variant.finishes[finishIdx].comment">
												"{{ variant.finishes[finishIdx].comment }}"
											</div>
											<div
												v-for="(color, colorIdx) in variant.finishes[finishIdx].colors"
												:key="colorIdx"
												class="color recommended d-flex align-items-center justify-content-start"
											>
												<div class="blob" :style="{ background: color.hexColor }"></div>
												<div class="name hex">{{ color.hexColor }}</div>
												<div
													v-if="color.recommendedRgbColor"
													class="mx-1 d-flex align-items-start justify-content-start"
												>
													<feather-icon icon="ArrowRightIcon" />
												</div>
												<div
													v-if="color.recommendedRgbColor"
													class="blob"
													:style="{ background: color.recommendedRgbColor }"
												></div>
												<div v-if="color.recommendedRgbColor" class="name">
													{{ color.recommendedColor }}
													{{ color.yarnNumber }}
												</div>
											</div>
										</div>
									</b-col>
								</b-row>
							</b-card>
						</b-col>
					</b-row>
				</div>
				<b-row class="pull-to-bottom">
					<b-col cols="12" class="size-block-wrapper">
						<b-card class="shadow-none size-block" v-for="(size, sizeIdx) in variant.sizes" :key="sizeIdx">
							<div class="inner">
								<div class="label">{{ size.size }}</div>
								<div class="number">{{ size.amount }}</div>
							</div>
						</b-card>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { BButton, BRow, BCol, BCard, BLink, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import FinishLabels from '@/assets/data/finishLabels.js'

import MockupPreview from './MockupPreview'
import RecommendColorModal from '../modals/RecommendColorModal'
import ChangeFileModal from '../modals/ChangeFileModal'
import TaskCheck from './partials/TaskCheck'

export default {
	name: 'Config',
	components: { BButton, BRow, BCol, BCard, BLink, MockupPreview, RecommendColorModal, BImg, TaskCheck, ChangeFileModal },
	props: {
		confCount: { type: Number, required: true },
		index: { type: Number, required: true },
		request: { type: Object, required: true },
		configuration: { type: Object, required: true },
		configurationIdx: { type: Number, required: true },
	},
	directives: {
		Ripple,
	},
	computed: {
		shopUrl() {
			const name = this.configuration.ref.name.toLowerCase().split(' ').join('-')
			return `https://www.stanleystella.com/${name}-${this.configuration.ref.externalArticleNumber}`
		},
		totalAmount() {
			let amount = 0
			for (let key of Object.keys(this.configuration.variants)) {
				const variant = this.configuration.variants[key]
				amount = variant.sizes.reduce((total, size) => total + size.amount, amount)
			}
			return amount
		},

		// ...mapGetters({
		// tasks: 'Requests/getTasks',
		// }),
		...mapFields('Requests', {
			requestFiles: 'files',
		}),
	},
	methods: {
		resolveLabel(type, value) {
			return FinishLabels[type][value]
		},
	},
}
</script>

<style lang="sass" scoped>
.change-file-btn
	flex-grow: 0

.file-download
	img
		max-height: 80px
		max-width: 100%
		background: rgba(0, 0, 0, 0.1)
		&.svg-img
			width: 100%

.comment-container
	font-size: 10px
	font-style: italic
	background-color: rgba(255, 204, 0, 0.6)
	border-radius: 4px
	padding: 8px
	color: black
	display: inline-block

.config-heading
	>div
		flex-grow: 0
		max-width: 100px

	.label
		font-size: 12px
		font-weight: 500

	.number
		font-weight: 700
		font-size: 22px

.custom-product-description
	background: rgba(255, 204, 0, 0.6)
	color: black
	font-style: italic
	white-space: pre-line
	padding: 10px
	border-radius: 0.428rem


.variant-settings
	position: relative
	.variant-label
		font-weight: 700
	h3
		margin-bottom: 0
	.shop
		font-size: 12px

.finish-settings
	font-size: 12px
	.technique
		font-weight: 700
		text-transform: capitalize
		font-size: 12px

.finish-row
	position: relative
	.details-button
		cursor: pointer

.colors
	font-size: 12px
	.color
		padding: 4px
		margin: 1px 0
		&.substitue
			background: rgba(255, 204, 0, 0.6)
			border-radius: 4px
		.name
			margin-left: 4px
			font-weight: 500
		.blob
			flex-grow: 0
			flex-shrink: 0
			width: 20px
			height: 20px
			border-radius: 50%
			border: 1px solid lightgrey

.colors-alt
	font-size: 12px
	.inner
		// display: flex
		// flex-wrap: wrap
		// flex-direction: column
		// flex-basis: 0
		// flex-grow: 0
		.color
			flex-grow: 0
			flex-shrink: 0
			flex-basis: 100%
			padding: 4px
			&.substitue
				// background: rgba(255, 204, 0, 0.6)
				// border-radius: 4px
			.name
				margin-left: 4px
				font-weight: 500
				width: 90px
				&.hex
					width: 70px
				.yarn-number
					font-size: 10px
			.blob
				flex-grow: 0
				flex-shrink: 0
				width: 20px
				height: 20px
				border-radius: 50%
				border: 1px solid lightgrey

// .pull-to-bottom
// 	position: absolute
// 	bottom: 0
// 	left: 0
// 	right: 0

.finish-row
	font-size: 10px

.size-block-wrapper
	display: flex
	flex-wrap: wrap
	justify-content: flext-start
	align-items: flex-start
	padding-right: 4px

.size-block
	flex-basis: calc(25% - 10px)
	margin-top: 15px
	margin-right: 10px
	margin-bottom: 0
	.inner
		display: flex
		justify-content: center
		align-items: center
		flex-direction: column
		.label
			font-weight: 700
			font-size: 14px
		.number
			font-weight: 700
			font-size: 14px
</style>
