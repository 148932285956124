<template>
	<div class="check-button">
		<b-button
			v-ripple.400="'rgba(113, 102, 240, 0.15)'"
			:variant="getTask(type, variantIdx, finishIdx) ? 'flat-primary' : 'flat-primary'"
			class="btn-icon rounded-circle"
			size="sm"
			@click="checkTask(type, variantIdx, finishIdx)"
		>
			<feather-icon size="16" :icon="activeIcon" v-if="getTask(type, variantIdx, finishIdx)" />
			<feather-icon size="16" :icon="inactiveIcon" v-else />
		</b-button>
	</div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { mapGetters } from 'vuex'

export default {
	name: 'TaskCheck',
	components: { BButton },
	props: {
		configurationIdx: { type: Number, required: false },
		variantIdx: { type: String, required: false },
		finishIdx: { type: String, required: false },
		activeIcon: { type: String, required: false, default: 'CheckCircleIcon' },
		inactiveIcon: { type: String, required: false, default: 'CircleIcon' },
		type: { type: String, required: true },
	},
	directives: {
		Ripple,
	},
	computed: {
		...mapGetters({
			tasks: 'Requests/getTasks',
		}),
	},
	methods: {
		getTask(type, variantIdx, finishIdx) {
			const exists = !!this.tasks.find(
				(t) =>
					t.type === type &&
					t.variantIdx === variantIdx &&
					t.finishIdx === finishIdx &&
					t.configurationIdx === this.configurationIdx
			)
			return exists
		},
		checkTask(type, variantIdx, finishIdx) {
			const action = this.getTask(type, variantIdx, finishIdx) ? 'Requests/uncheckTask' : 'Requests/checkTask'
			this.$store.dispatch(action, { type, variantIdx, finishIdx, configurationIdx: this.configurationIdx })
		},
	},
}
</script>

<style lang="sass" scoped>
.check-button
    position: absolute
    top: 4px
    right: 4px
</style>