export default {
	technique: {
		print: 'Siebdruck',
		stick: 'Stick',
	},
	sizeMeasure: {
		width: 'Breite',
		height: 'Höhe',
	},
	sizeMeasure_alt: {
		width: 'breit',
		height: 'hoch',
	},
	side: {
		front: 'Vorne',
		left: 'Links',
		right: 'Rechts',
		back: 'Hinten',
		'shoulder-sleeve': 'Ärmel',
	},
	position: {
		'custom-front': 'Custom',
		'front-left': 'Links',
		'front-center': 'Mittig',
		'front-right': 'Rechts',
		'custom-back': 'Custom',
		'custom-left': 'Custom',
		'custom-right': 'Custom',
		'back-center': 'Mittig',
		'shoulder-sleeve-left-custom': 'Links',
		'shoulder-sleeve-right-custom': 'Rechts',
	},
	colorType: {
		recommendation: 'Farbvorschlag',
		multiple: 'Mehrfarbig',
		single: 'Einfarbig',
	},
}
