<template>
	<div class="hideOnPrint">
		<b-card variant="primary" class="mb-0 text-center status-card remove-bottom-radius">
			<b>{{ statusLabels.find((b) => b.status === request.status).label }}</b>
		</b-card>
		<!-- <b-button variant="primary" block class="remove-bottom-radius" disabled>
			{{ statusLabels.find((b) => b.status === request.status).label }}
		</b-button> -->
		<b-card class="shadow-none remove-top-radius">
			<b-row>
				<b-col cols="8">
					<b-badge variant="primary">{{ formatedCreatedAt }}</b-badge>
					<h3 class="mt-1 mb-0">{{ request.publicId }}</h3>
				</b-col>
				<b-col cols="4">
					<b-navbar-nav class="nav align-items-end ml-auto">
						<b-nav-item-dropdown
							right
							toggle-class="d-flex align-items-end justify-content-start dropdown-user-link pt-0 mt-0"
							class="dropdown-user assignee-dropdown"
						>
							<template #button-content>
								<div v-if="request.assignedUser" class="d-flex align-items-end justify-content-center flex-column">
									<span class="assignee" v-if="!assigningUser">{{ request.assignedUser.firstname }}</span>
									<b-avatar variant="info" :src="request.assignedUser.avatar" v-if="!assigningUser"></b-avatar>
									<b-spinner variant="primary" v-if="assigningUser"></b-spinner>
								</div>
								<div v-else>
									<b-avatar variant="info" v-if="!assigningUser"></b-avatar>
									<b-spinner variant="primary" v-if="assigningUser"></b-spinner>
								</div>
							</template>

							<b-dropdown-item
								link-class="d-flex align-items-center"
								v-for="(employee, employeeIdx) in employees"
								:key="employeeIdx"
								@click="assignUser(employee)"
							>
								<b-avatar size="24" variant="info" :src="employee.avatar"></b-avatar>
								<span class="ml-1">{{ employee.firstname }} {{ employee.lastname }}</span>
							</b-dropdown-item>
						</b-nav-item-dropdown>
					</b-navbar-nav>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<div class="mt-1">Produktion: <PrintModal /></div>
					<div v-if="request.sevdesk">
						<div v-if="request.sevdesk.offerPdf" class="document-link">
							Angebot: <a :href="request.sevdesk.offerPdf" target="_blank">{{ request.sevdesk.offerNumber }}</a>
						</div>
						<div v-if="request.sevdesk.orderConfirmationPdf" class="document-link">
							Auftragsbestätigung:
							<a :href="request.sevdesk.orderConfirmationPdf" target="_blank">{{
								request.sevdesk.orderConfirmationNumber
							}}</a>
						</div>
						<div v-if="request.sevdesk.invoicePdf" class="document-link">
							Rechnung: <a :href="request.sevdesk.invoicePdf" target="_blank">{{ request.sevdesk.invoiceNumber }}</a>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<div v-if="request.payment" class="mt-1">
						<div>
							<b>{{ paymentServiceLabels[request.payment.paymentService] }}</b>
						</div>
						<div v-if="request.payment.paymentRecievedAt">
							<small>erhalten am {{ formatDateTime(request.payment.paymentRecievedAt) }}</small>
						</div>
						<div v-else>
							<small>Zahlung noch nicht erhalten</small>
						</div>
					</div>
				</b-col>
			</b-row>
			<hr />
			<b-row>
				<b-col cols="12">
					<h3>{{ request.customer.firstname }} {{ request.customer.lastname }}</h3>
					<h3 class="mb-0">{{ request.customer.postalCode }} {{ request.customer.city }}</h3>
					{{ request.customer.country }}<br />
					{{ request.customer.email }}<br />
					{{ request.customer.phone }}
				</b-col>
				<!-- <b-col cols="5" class="d-flex align-items-end flex-column">
					<b-badge variant="warning">Neuer Kunde</b-badge>
				</b-col> -->
			</b-row>
			<hr />
			<b-row>
				<b-col class="d-flex align-items-center justify-content-center flex-column">
					<h1 class="counter-number">{{ request.configurations.length }}</h1>
					<span class="counter-label">Konfigurationen</span>
				</b-col>
				<!-- <b-col class="d-flex align-items-center justify-content-center flex-column">
					<h1 class="counter-number">{{ finishCount }}</h1>
					<span class="counter-label">Motive</span>
				</b-col> -->
				<b-col class="d-flex align-items-center justify-content-center flex-column">
					<h1 class="counter-number">{{ totalAmount }}</h1>
					<span class="counter-label">Textilien</span>
				</b-col>
			</b-row>
			<hr />
			<b-row>
				<b-col cols="2">
					<feather-icon icon="UserIcon" class="mr-50" />
				</b-col>
				<b-col cols="7" class="d-flex align-items-center item-label"> Kundendaten </b-col>
				<b-col cols="3">
					<feather-icon
						:icon="taskIsValid('customer', 0) ? 'CheckCircleIcon' : 'CircleIcon'"
						size="24"
						:class="taskIsValid('customer', 0) ? 'checked-icon' : 'unchecked-icon'"
					/>
				</b-col>
			</b-row>
			<div v-for="(config, configIdx) of request.configurations" :key="configIdx">
				<b-row>
					<b-col cols="12" class="pt-2 pb-1">
						<b>Konfiguration {{ parseInt(configIdx) + 1 }}</b>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="2">
						<feather-icon icon="FileIcon" class="mr-50" />
					</b-col>
					<b-col cols="7" class="d-flex align-items-center item-label"> Motiv </b-col>
					<b-col cols="3">
						<feather-icon
							:icon="taskIsValid('motive', configIdx) ? 'CheckCircleIcon' : 'CircleIcon'"
							size="24"
							:class="taskIsValid('motive', configIdx) ? 'checked-icon' : 'unchecked-icon'"
						/>
					</b-col>
				</b-row>
				<hr />
				<b-row>
					<b-col cols="2">
						<feather-icon icon="ArchiveIcon" class="mr-50" />
					</b-col>
					<b-col cols="7" class="d-flex align-items-center flex-wrap item-label"> Textilienverfügbarkeit </b-col>
					<b-col cols="3">
						<feather-icon
							:icon="taskIsValid('textile', configIdx) ? 'CheckCircleIcon' : 'CircleIcon'"
							size="24"
							:class="taskIsValid('textile', configIdx) ? 'checked-icon' : 'unchecked-icon'"
						/>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<div class="action-buttons hideOnPrint" v-if="request.assignedUser">
			<b-row v-if="request.status === 'PROJECT-REFUSED' || request.status === 'CUSTOMER_CANCELLATION'">
				<b-col>
					<b-button variant="info" block @click="reopenProject"> Anfrage wieder öffnen </b-button>
				</b-col>
			</b-row>
			<b-row class="mt-1" v-if="request.status !== 'PAYMENT_RECEIVED'">
				<b-col>
					<SetPaymentModal :request="request" />
					<!-- <b-button
						variant="primary"
						block
						:disabled="
							request.status !== 'INVOICE_CREATED' ||
							!request.payment ||
							(request.payment && request.payment.paymentService !== 'bank-transfer')
						"
						@click="markProjectAsPayed"
					>
						Angebot als bezahlt markieren
					</b-button> -->
				</b-col>
			</b-row>
			<!-- <b-row v-if="request.status !== 'PROJECT-REFUSED' && request.status !== 'OFFER_ACCEPTED_AWAITING_PAYMENT'"> -->
			<b-row class="mt-1" v-if="request.status !== 'PAYMENT_RECEIVED'">
				<b-col>
					<OfferModal
						:request="request"
						:disabled="
							!requestValidation ||
							request.status === 'PROJECT-REFUSED' ||
							request.status === 'CUSTOMER_CANCELLATION' ||
							request.status === 'OFFER_ACCEPTED_AWAITING_PAYMENT' ||
							request.status === 'INVOICE_CREATED' ||
							request.status === 'PAYMENT_RECEIVED'
						"
					/>
				</b-col>
			</b-row>
			<!-- <b-row class="mt-1">
				<b-col>
					<b-button variant="secondary" block @click="printPDF"> Produktions PDF </b-button>
				</b-col>
			</b-row> -->
			<b-row class="mt-1" v-if="request.status !== 'PAYMENT_RECEIVED'">
				<b-col>
					<ChangeConfiguratorModal
						:disabled="request.status === 'PROJECT-REFUSED' || request.status === 'OFFER_ACCEPTED_AWAITING_PAYMENT' || true"
					/>
				</b-col>
			</b-row>

			<b-row class="mt-1" v-if="request.status === 'PAYMENT_RECEIVED'">
				<b-col>
					<b-button variant="success" block> Produktion starten </b-button>
				</b-col>
			</b-row>

			<b-row class="mt-1" v-if="request.status !== 'PROJECT-REFUSED'">
				<b-col>
					<b-button
						variant="danger"
						block
						@click="refuseProject"
						:disabled="request.status === 'CUSTOMER_CANCELLATION' || request.status.match('PRODUCTION')"
					>
						Anfrage ablehnen
					</b-button>
				</b-col>
			</b-row>
		</div>
		<div v-else class="text-center">
			<h5>
				<b-badge variant="secondary" style="word-break: break-word; white-space: pre-wrap; line-height: 1.5em; font-weight: 500"
					>Projekt nicht assigned.</b-badge
				>
			</h5>
		</div>
	</div>
</template>

<script>
import {
	BRow,
	BCol,
	BButton,
	BCard,
	BFormCheckbox,
	BAvatar,
	BBadge,
	BNavItemDropdown,
	BDropdownItem,
	BNavbarNav,
	BSpinner,
} from 'bootstrap-vue'

import { mapGetters } from 'vuex'

import { setBankTransferPayment } from '@/api/requests.api'

import dayjs from 'dayjs'
require('dayjs/locale/de')
dayjs.locale('de')

import OfferModal from '../modals/OfferModal'
import SetPaymentModal from '../modals/SetPaymentModal'
import ChangeConfiguratorModal from '../modals/ChangeConfiguratorModal'
import PrintModal from '../modals/PrintModal'

export default {
	name: 'StatusBox',
	components: {
		BRow,
		BCol,
		BButton,
		BCard,
		BFormCheckbox,
		BAvatar,
		BBadge,
		BNavItemDropdown,
		BDropdownItem,
		BNavbarNav,
		BSpinner,
		OfferModal,
		ChangeConfiguratorModal,
		SetPaymentModal,
		PrintModal,
	},
	props: {
		request: { type: Object, required: true },
	},
	data() {
		return {
			statusLabels: [
				{ status: 'PENDING', label: 'In Bearbeitung' },
				{ status: 'AWAITING_CONFIRMATION', label: 'Angebot bereit' },
				{ status: 'NEW_CUSTOMER_MESSAGE', label: 'Anfrage in Bearbeitung' },
				{ status: 'AWATING_UPLOAD', label: 'Upload benötigt' },
				{ status: 'AWATING_INFO', label: 'Information benötigt' },
				{ status: 'OFFER_ACCEPTED_AWAITING_PAYMENT', label: 'Zahlung ausstehend' },
				{ status: 'INVOICE_CREATED', label: 'Rechnung erstellt' },
				{ status: 'PAYMENT_RECEIVED', label: 'Zahlung erhalten' },
				{ status: 'PROJECT_REFUSED', label: 'Projekt abgelehnt' },
				{ status: 'CUSTOMER_CANCELLATION', label: 'Projekt vom Kunden abgesagt' },
				{ status: 'PRE_PRODUCTION', label: 'Produktion in Vorbereitung' },
				{ status: 'PRODUCTION', label: 'In Produktion' },
				{ status: 'ON_HOLD', label: 'Produktion pausiert' },
				{ status: 'AWAITING_CUSTOMER_CHECK', label: 'Kundenfreigabe benötigt' },
				{ status: 'POST_PRODUCTION', label: 'In Nachbearbeitung' },
				{ status: 'SHIPPED', label: 'Versendet' },
				{ status: 'COMPLETED', label: 'Projekt Abgeschlossen' },
			],
			paymentServiceLabels: {
				'bank-transfer': 'Bank-Überweisung',
				paypal: 'PayPal',
				klarna: 'Klarna',
			},
			employees: [],
			assigningUser: false,
		}
	},
	mounted() {
		this.$store.dispatch('Employees/fetchEmployees', { status: 'active' }).then((response) => {
			this.employees = response.data.users
		})
	},
	computed: {
		...mapGetters({
			taskValidation: 'Requests/getTaskValidation',
			requestValidation: 'Requests/getRequestValidation',
		}),
		finishCount() {
			let count = 0
			this.request.configurations.forEach((config) => {
				count += Object.keys(config.finishes).length
			})
			return count
		},
		totalAmount() {
			let amount = 0
			this.request.configurations.forEach((config) => {
				for (let key of Object.keys(config.variants)) {
					const variant = config.variants[key]
					amount = variant.sizes.reduce((total, size) => total + size.amount, amount)
				}
			})
			return amount
		},
		formatedCreatedAt() {
			return dayjs(this.request.createdAt).format('DD. MMMM YYYY')
		},
	},
	methods: {
		formatDateTime(date) {
			return dayjs(date).format('DD.MM.YYYY, HH:mm') + 'Uhr'
		},
		taskIsValid(type, configIdx) {
			const configValidation = this.taskValidation.find((v) => v.configIdx === configIdx)
			return configValidation && configValidation[type]
		},
		reopenProject() {
			this.$store.dispatch('Requests/update', { id: this.request._id, data: { status: 'PENDING' } })
		},
		refuseProject() {
			this.$store.dispatch('Requests/update', { id: this.request._id, data: { status: 'PROJECT-REFUSED' } })
		},
		assignUser(employee) {
			console.log('loadUsers')
			console.log(employee)
			this.assigningUser = true
			this.$store.dispatch('Requests/assignUser', { id: this.request._id, employee }).finally(() => (this.assigningUser = false))
		},
		markProjectAsPayed() {
			setBankTransferPayment(this.request._id)
		},
		printPDF(ev) {
			if (ev) ev.preventDefault()
			window.print()
			// const routeData = this.$router.resolve({
			// 	name: 'wercbrain-request-details',
			// 	params: { requestId: this.$route.params.requestId },
			// 	query: { print: 1 },
			// })
			// window.open(routeData.href, '_blank')
		},
	},
}
</script>

<style lang="sass" scoped>
.document-link
	margin-top: 4px
.status-card
	background: black
	background: #7367f0
	color: white
	font-size: 1.2rem
	.card-body
		padding-left: 5px
		padding-right: 5px
.assignee
	// color: #B9B9C3
	margin-bottom: 4px
.remove-bottom-radius
	border-radius: 0.358rem 0.358rem 0 0

.remove-top-radius
	border-radius: 0 0 0.358rem 0.358rem

.customer-badge
	font-size: 10px
	font-weight: 500
	padding: 6px 16px

.counter-number
	font-weight: 700
.counter-label
	font-size: 11px

.item-label
	word-break: break-all
.checked-icon
	color: #7367f0
.unchecked-icon
	color: lightgrey

button.disabled
	opacity: 0.15
.assignee-dropdown
	:after
		display: none
</style>
