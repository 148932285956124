<template>
	<div class="wrapper">
		<a href="" @click="showModal" target="_blank">PDF</a>

		<b-modal id="print-modal" v-model="modal" hide-footer scrollable size="md" title="Produktions PDF drucken">
			<b-row no-gutters class="full-height">
				<b-col cols="12" class="left my-2">
					<div>
						<p>Bitte in den Druckeinstellungen die Option für <b>Hintergrundgrafiken</b> aktivieren</p>
					</div>
					<div class="text-center mt-2">
						<b-button variant="primary" @click="printPDF">Drucken</b-button>
					</div>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import {
	BButton,
	BModal,
	BRow,
	BCol,
	BFormInput,
	BFormDatepicker,
	BInputGroup,
	BInputGroupAppend,
	BDropdown,
	BDropdownItem,
	BFormSelect,
	BSpinner,
} from 'bootstrap-vue'

export default {
	name: 'OfferModal',
	components: {
		BButton,
		BModal,
		BRow,
		BCol,
		BFormInput,
		BFormDatepicker,
		BInputGroup,
		BInputGroupAppend,
		BDropdown,
		BDropdownItem,
		BFormSelect,
		BSpinner,
	},
	data() {
		return {
			modal: false,
		}
	},
	methods: {
		showModal(ev) {
			ev.preventDefault()
			this.modal = true
		},
		closeModal() {
			this.modal = false
		},
		printPDF() {
			this.modal = false
			setTimeout(() => {
				window.print()
			}, 200)
		},
	},
}
</script>

<style lang="sass">
// #print-modal
// 	.modal-dialog
// 		height: 100vh
// 		max-width: 100%
// 		margin: 0
// 		top: 0
// 		bottom: 0
// 		left: 0
// 		right: 0
// 		height: 100vh
// 		max-height: 100vh
// 		display: flex
// 		position: fixed
// 		z-index: 100000
// 		.modal-content
// 			height: 100vh
// 			max-height: 100vh
// 	.modal-body
// 		padding: 0
</style>

<style lang="sass" scoped>
.wrapper
	display: inline-block
</style>
