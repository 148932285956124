<template>
	<div>
		<b-button variant="flat-primary" class="btn-icon rounded-circle" size="sm" @click="dialog = true">
			<feather-icon icon="Edit2Icon" />
		</b-button>
		<b-modal
			size="xl"
			id="color-selection-modal"
			title="Farbe ändern"
			v-model="dialog"
			fullscreen
			ok-title="Speichern"
			cancel-title="Abbrechen"
		>
			<div id="color-selection-modal">
				<div class="pa-4 mb-1" v-if="finish.technique !== 'stick' || (finish.technique === 'stick' && stickCategory)">
					<b-form-input v-model="searchInput" placeholder="Suche" label="Suche"></b-form-input>
				</div>
				<div v-if="finish.technique !== 'stick'" class="color-scroll-box">
					<div class="d-flex flex-wrap align-content-start">
						<div
							v-for="(color, idx) of filteredColors"
							:key="idx"
							class="pantone-color-item d-flex justify-content-center align-items-center text-center flex-column"
							:style="{ background: color.color }"
							@click="selectColor(color)"
						>
							{{ color.name }}
							<i style="font-weight: 300">{{ color.pantone }}</i>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="select-category d-flex justify-content-center align-items-center text-center pt-2" v-if="!stickCategory">
						<b-card style="box-shadow: none">
							<h4>Standard</h4>
							<p>Glänzende Garne..</p>
							<b-button variant="primary" size="sm" @click="selectCategory('standard')">Auswählen</b-button>
						</b-card>
						<b-card class="ml-2" style="box-shadow: none">
							<h4>Matt</h4>
							<p>Lorem ipsum..</p>
							<b-button variant="primary" size="sm" @click="selectCategory('frosted')">Auswählen</b-button>
						</b-card>
						<b-card class="ml-2" style="box-shadow: none">
							<h4>Polyneon</h4>
							<p>Coming soon..</p>
							<b-button variant="primary" size="sm" disabled @click="selectCategory('polyneon')">Auswählen</b-button>
						</b-card>
						<b-card class="ml-2" style="box-shadow: none">
							<h4>Green</h4>
							<p>Coming soon..</p>
							<b-button variant="primary" size="sm" disabled @click="selectCategory('green')">Auswählen</b-button>
						</b-card>
						<b-card class="ml-2" style="box-shadow: none">
							<h4>Sensa</h4>
							<p>Coming soon..</p>
							<b-button variant="primary" size="sm" disabled @click="selectCategory('sensa')">Auswählen</b-button>
						</b-card>
					</div>
					<div v-if="stickCategory">
						<div class="d-flex align-items-center mt-2 mb-2 stick-category-header">
							<h4 class="m-0">{{ stickCategoryLabels[stickCategory] }}</h4>
							<b-button variant="flat-primary" class="btn-icon rounded-circle ml-1" size="sm" @click="stickCategory = null">
								<feather-icon icon="Edit2Icon" />
							</b-button>
						</div>
						<div class="d-flex flex-wrap align-content-start color-scroll-box">
							<div
								v-for="(color, idx) of filteredColors"
								:key="idx"
								class="pantone-color-item d-flex justify-content-center align-items-center text-center flex-column"
								:style="{ background: color.color }"
								@click="selectColor(color)"
							>
								{{ color.name }}
								<i style="font-weight: 300">{{ color.pantone }}</i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { BButton, BCard, BFormInput } from 'bootstrap-vue'
import PantoneColorsArray from '@/assets/data/pantone.js'
import { standard as PantoneStickStandardColorsArray, frosted as PantoneStickFrostedColorsArray } from '@/assets/data/pantone-stick.js'
// import { mapFields } from 'vuex-map-fields'

export default {
	name: 'ColorSelectionModal',
	components: { BButton, BCard, BFormInput },
	props: {
		selectFn: { type: Function, required: true },
		color: { type: Object, required: true },
		finish: { type: Object, required: true },
	},
	data() {
		return {
			dialog: false,
			pantoneColors: PantoneColorsArray,
			pantoneStickColors: {
				standard: PantoneStickStandardColorsArray,
				frosted: PantoneStickFrostedColorsArray,
			},
			searchInput: '',
			filteredColors: [],
			stickCategory: null,
			stickCategoryLabels: {
				standard: 'Standard-Garne (glänzend)',
				frosted: 'Matt-Garne',
			},
		}
	},
	mounted() {
		const pantoneColorArray = this.finish.technique === 'stick' ? this.pantoneStickColors[this.stickCategory] : this.pantoneColors
		this.filteredColors = pantoneColorArray
	},
	watch: {
		searchInput(val) {
			val = val.replace(/\s*/g, '')
			const pantoneColorArray = this.finish.technique === 'stick' ? this.pantoneStickColors[this.stickCategory] : this.pantoneColors
			this.filteredColors = pantoneColorArray.filter(
				(c) => c.name.replace(/\s*/g, '').match(new RegExp(val, 'gi')) || c.pantone.replace(/\s*/g, '').match(new RegExp(val, 'gi'))
			)
		},
	},
	methods: {
		selectColor(pantone) {
			this.selectFn(pantone, this.color)
			this.dialog = false
		},
		selectCategory(category) {
			this.stickCategory = category

			const pantoneColorArray = this.finish.technique === 'stick' ? this.pantoneStickColors[this.stickCategory] : this.pantoneColors
			this.filteredColors = pantoneColorArray
		},
	},
}
</script>

<style lang="sass">
#color-selection-modal
	.color-scroll-box
		height: calc(100vh - 300px)
		overflow-y: auto
	.pantone-color-item
		color: black
		width: calc(100% / 10)
		height: 100px
		cursor: pointer
		font-size: 10px
		font-weight: 600
		letter-spacing: 1px
		position: relative
		z-index: 9
		&:hover
			outline: 4px solid white
			z-index: 10
	.select-category
		flex-wrap: wrap
		.card
			flex-basis: 200px
</style>