<template>
	<div>
		<b-button
			size="sm"
			block
			style="font-size: 11px; width: auto"
			@click="modal = !modal"
			v-if="
				request.status !== 'INVOICE_CREATED' ||
				!request.payment ||
				(request.payment && request.payment.paymentService !== 'bank-transfer')
			"
			>Farbvorschlag</b-button
		>
		<!-- <div class="button" @click="modal = !modal">Farbvorschlag</div> -->

		<b-modal
			id="recommend-color-modal"
			title="Farbvorschlag bearbeiten"
			v-model="modal"
			ok-title="Speichern"
			cancel-title="Abbrechen"
			:busy="saving"
			@ok="(ev) => save(ev)"
			size="lg"
		>
			<b-row>
				<b-col cols="6">
					<div class="my-1">
						<img :src="$getFileUrlById(finish.fileName)" width="100%" style="max-height: 400px" />
					</div>
				</b-col>
				<b-col cols="6">
					<div class="my-1">
						<!-- <b>{{ resolveLabel(variantFinish.type) }}</b> -->
						<div class="my-1">
							<b-button variant="secondary" class="" size="sm" @click="reset">
								<feather-icon icon="RotateCcwIcon" /> Zurücksetzen
							</b-button>
						</div>

						<div v-if="variantFinish.comment" class="comment-container">"{{ variantFinish.comment }}"</div>

						<div class="my-1" v-if="!extracting">
							<div
								v-for="(color, colorIdx) in extractedColors"
								:key="colorIdx"
								class="color-item d-flex align-items-center justify-content-between"
							>
								<div class="d-flex align-items-center">
									<div class="color-blob" :style="`background-color: ${color.hexColor}`"></div>
									<div class="name">{{ color.hexColor }}</div>
									<div v-if="color.recommendedRgbColor" class="mx-1 d-flex align-items-start justify-content-start">
										<feather-icon icon="ArrowRightIcon" />
									</div>
									<div
										class="color-blob"
										:style="`background-color: ${color.recommendedRgbColor}`"
										v-if="color.recommendedRgbColor"
									></div>
									<div class="name" v-if="color.recommendedRgbColor">{{ color.recommendedColor }}</div>
								</div>
								<div class="d-flex align-items-center">
									<ColorSelectionModal :selectFn="editColor" :color="color" :finish="finish" />
									<!-- <b-button
										variant="flat-danger"
										class="btn-icon rounded-circle"
										size="sm"
										@click="removeColor(color.recommendedRgbColor)"
									>
										<feather-icon icon="Trash2Icon" />
									</b-button> -->
								</div>
							</div>
						</div>
						<div v-else class="my-1 d-flex align-items-center">
							<b-spinner variant="secondary" size="sm"></b-spinner>
							<div class="ml-1">Farben werden ausgelesen..</div>
						</div>
					</div>
				</b-col>
			</b-row>
			<div></div>
		</b-modal>
	</div>
</template>

<script>
import { BButton, BModal, BSpinner, BRow, BCol } from 'bootstrap-vue'
import FinishLables from '@/assets/data/finishLabels'
import ColorSelectionModal from './ColorSelectionModal.vue'

export default {
	name: 'RecommendColorModal',
	components: { BButton, BModal, BSpinner, BRow, BCol, ColorSelectionModal },
	props: {
		variantFinish: { type: Object, required: true },
		variantIdx: { type: String, required: true },
		finish: { type: Object, required: true },
		finishIdx: { type: String, required: true },
		files: { type: Array, required: true },
		configIdx: { type: String, required: true },
		requestId: { type: String, required: true },
		request: { type: Object, required: true },
	},
	data() {
		return {
			modal: false,
			extractedColors: [],
			extracting: false,
			saving: false,
		}
	},
	watch: {
		modal(isOpen) {
			if (isOpen) {
				if (this.variantFinish.colors.length > 0) {
					this.extractedColors = this.variantFinish.colors
				} else {
					this.extractColors()
				}
			}
		},
	},
	methods: {
		extractColors() {
			const imgUrl = this.$getFileUrlById(this.finish.fileName)
			this.extracting = true
			this.$store
				.dispatch('Requests/extractColors', imgUrl)
				.then(({ colors }) => {
					this.extractedColors = colors.map((c) => ({
						hexColor: c.hex,
						recommendedColor: c.hex,
						recommendedRgbColor: c.hex,
					}))
				})
				.finally(() => {
					this.extracting = false
				})
		},
		resolveLabel(value) {
			return FinishLables.colorType[value]
		},
		reset() {
			this.extractedColors = []
			this.extractColors()
		},
		editColor(pantoneColor, color) {
			this.extractedColors = this.extractedColors.map((c) => {
				if (c.hexColor === color.hexColor) {
					return {
						hexColor: c.hexColor,
						recommendedColor: pantoneColor.pantone,
						recommendedRgbColor: pantoneColor.color,
						yarnNumber: pantoneColor.productNumber,
					}
				}
				return c
			})
		},
		removeColor(hex) {
			const idx = this.extractedColors.findIndex((c) => c.hexColor === hex)
			this.extractedColors.splice(idx, 1)
		},
		save(ev) {
			ev.preventDefault()

			const data = {}
			const keyString = `configurations.${this.configIdx}.variants.${this.variantIdx}.finishes.${this.finishIdx}.colors`
			data[keyString] = this.extractedColors

			this.saving = true
			this.$store.dispatch('Requests/update', { id: this.requestId, data }).finally(() => {
				this.saving = false
				this.modal = false
			})
		},
	},
}
</script>

<style lang="sass" scoped>
.button
	cursor: pointer

.color-item
	width: 100%
	max-width: 400px
	margin: 10px 0
	border-radius: 4px
	transition: all 200ms
	padding: 4px
	&:hover
		background-color: rgba(0,0,0,0.05)
	.color-blob
		width: 20px
		height: 20px
		border-radius: 50%
		border: 1px solid lightgrey
	.name
		margin-left: 8px
		width: 90px

.comment-container
	font-size: 10px
	font-style: italic
	background-color: rgba(255, 204, 0, 0.6)
	border-radius: 4px
	padding: 8px
	color: black
	display: inline-block
</style>