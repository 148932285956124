<template>
	<div>
		<b-button variant="success" block @click="modal = true" :disabled="disabled"> Angebot erstellen </b-button>

		<b-modal id="offer-modal" v-model="modal" hide-header hide-footer scrollable size="xl" :no-close-on-backdrop="creatingOffer">
			<div v-if="isSaving" class="saving-overlay">
				<div>Angebot wird aktualisiert</div>
				<div class="small">Bitte warten..</div>
			</div>
			<b-row no-gutters class="full-height">
				<b-col cols="12" class="left" v-if="request.costs && request.costs.productionCosts">
					<div class="configs">
						<div class="config" v-for="(config, configIdx) of request.configurations" :key="configIdx">
							<div class="d-flex">
								<div class="conf-label d-flex align-items-center justify-content-center">
									<div class="text">
										<div class="label">Konfiguration</div>
										<div class="number">{{ parseInt(configIdx) + 1 }}</div>
									</div>
								</div>
								<div style="flex-grow: 1">
									<div class="conf-costs d-flex flex-row">
										<div class="extra-costs">
											<div class="finish-header">
												<h5>Nebenkosten <span class="light">Print</span></h5>
											</div>
											<div class="">
												<AmountInput
													label="Siebeinrichtung"
													:value="
														(request.costs.productionCosts[configIdx] &&
															request.costs.productionCosts[configIdx].printScreenSetup.costs) ||
														0
													"
													:amount="
														(request.costs.productionCosts[configIdx] &&
															request.costs.productionCosts[configIdx].printScreenSetup.amount) ||
														0
													"
													showAmount
													valueKey="extra.printScreenSetup"
												/>
												<AmountInput
													label="Palettewechsel"
													:value="
														(request.costs.productionCosts[configIdx] &&
															request.costs.productionCosts[configIdx].paletteChange.costs) ||
														0
													"
													:amount="
														(request.costs.productionCosts[configIdx] &&
															request.costs.productionCosts[configIdx].paletteChange.amount) ||
														0
													"
													:updateKey="`configurations.${configIdx}.paletteChangeCount`"
													:updateFn="updateCosts"
													:editable="true"
												/>
												<AmountInput
													label="Farbwechsel"
													:value="
														(request.costs.productionCosts[configIdx] &&
															request.costs.productionCosts[configIdx].colorChange.costs) ||
														0
													"
													:amount="
														(request.costs.productionCosts[configIdx] &&
															request.costs.productionCosts[configIdx].colorChange.amount) ||
														0
													"
													showAmount
												/>
											</div>
										</div>
										<div class="extra-costs stick">
											<div class="finish-header">
												<h5>Nebenkosten <span class="light">Stick</span></h5>
											</div>
											<div class="">
												<AmountInput
													label="Stick Standard"
													:value="
														(request.costs.productionCosts[configIdx] &&
															request.costs.productionCosts[configIdx].stickStandard.costs) ||
														0
													"
												/>
												<AmountInput
													label="Stickdatei"
													:value="
														(request.costs.productionCosts[configIdx] &&
															request.costs.productionCosts[configIdx].stickFile.costs) ||
														0
													"
													:amount="
														(request.costs.productionCosts[configIdx] &&
															request.costs.productionCosts[configIdx].stickFile.amount) ||
														0
													"
													showAmount
												/>
												<AmountInput
													label="Rahmenwechsel"
													:value="
														(request.costs.productionCosts[configIdx] &&
															request.costs.productionCosts[configIdx].frameChange.costs) ||
														0
													"
													:amount="
														(request.costs.productionCosts[configIdx] &&
															request.costs.productionCosts[configIdx].frameChange.amount) ||
														0
													"
													:updateKey="`configurations.${configIdx}.frameChangeCount`"
													:updateFn="updateCosts"
													:editable="true"
												/>
											</div>
										</div>
										<div class="extra-costs misc">
											<!-- <div class="finish-header sub"> -->
											<div class="finish-header">
												<h5>Nebenkosten <span class="light">Allgemein</span></h5>
											</div>
											<div class="">
												<AmountInput
													label="Überschuss (Textilien) pro Textilfarbe"
													:value="
														(request.costs.productionCosts[configIdx] &&
															request.costs.productionCosts[configIdx].surplus.costs) ||
														0
													"
													:amount="
														(request.costs.productionCosts[configIdx] &&
															request.costs.productionCosts[configIdx].surplus.amount) ||
														0
													"
													:updateKey="`configurations.${configIdx}.surplusCount`"
													:updateFn="updateCosts"
													:editable="true"
												/>
												<PriceInput
													label="Zusatzkosten"
													:value="
														(request.costs.productionCosts[configIdx] &&
															request.costs.productionCosts[configIdx].extraCosts) ||
														0
													"
													:updateKey="`configurations.${configIdx}.extraCosts`"
													:updateFn="updateCosts"
													:editable="true"
												/>
											</div>
										</div>
									</div>
									<div class="motives-header">
										<h5>Motive</h5>
									</div>
									<div class="finish-costs-wrapper d-flex flex-row">
										<div class="finish-costs">
											<div v-for="(finish, finishIdx) in config.finishes" :key="finishIdx" class="finish-costs-loop">
												<FinishRow
													:finish="finish"
													:finishIdx="parseInt(finishIdx)"
													:variants="config.variants"
													:request="request"
													:updateCosts="updateCosts"
													:configIdx="parseInt(configIdx)"
												/>
											</div>
										</div>
									</div>
									<!-- <AdditionalCosts
										:config="config"
										:productionCosts="request.costs.productionCosts[configIdx]"
										:updateKey="`configurations.${configIdx}.additionalCosts`"
										:updateFn="updateCosts"
									/> -->
									<div class="divider"></div>
									<div class="variant" v-for="(variant, variantIdx) of config.variants" :key="variantIdx">
										<CostVariant
											:request="request"
											:config="config"
											:configIdx="parseInt(configIdx)"
											:variant="variant"
											:variantIdx="parseInt(variantIdx)"
											v-if="request.costs"
										/>
										<div class="divider"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="config">
							<div class="d-flex">
								<div class="conf-label d-flex align-items-center justify-content-center">
									<div class="text">
										<div class="label">Zusatzkosten</div>
										<div class="number"></div>
									</div>
								</div>
								<div style="flex-grow: 1">
									<div class="conf-costs d-flex flex-row">
										<GlobalAdditionalCosts :request="request" :updateFn="updateCosts" updateKey="additionalCosts" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="total-box">
						<div class="additional-costs d-flex">
							<div class="d-flex justify-content-center align-items-center flex-row" style="width: 640px">
								Versand:
								<PriceInput
									label=""
									class="ml-1 mr-1"
									:value="formattedShippingBoxCount || 0"
									updateKey="shippingBoxCount"
									:updateFn="updateCosts"
									:editable="true"
									currency="Paket(e)"
									singleInput
								/>
								<b>Paket(e)</b>
								<span class="ml-1">/</span>
								<PriceInput label="" class="ml-2 mr-1" :value="request.costs.shippingCosts || 0" singleInput />
								<b>€</b>

								<span class="ml-4"></span>
								<div>
									<div>Versandrabatt:</div>
									<div style="font-size: 10px" class="text-capitalize">{{ request.shippingType }}</div>
								</div>

								<PriceInput label="" class="ml-2 mr-1" :value="request.costs.shippingDiscount || 0" singleInput />
								<b>€</b>
							</div>
						</div>
						<div class="small mr-2">Insgesamt</div>
						<div class="big">{{ request.costs.costsTotal && request.costs.costsTotal.toFixed(2) }}€</div>
						<div class="action-button-wrapper ml-2 d-flex">
							<div class="full-width text-center" v-if="creatingOffer">
								<b-spinner variant="primary"></b-spinner>
							</div>
							<b-button
								variant="primary"
								class="create-offer-btn shadow-none d-flex align-items-center"
								@click="createOffer"
								v-if="
									!creatingOffer &&
									((request.sevdesk && request.costs.updatedAt > request.sevdesk.offerCreatedAt) ||
										!request.sevdesk ||
										(request.sevdesk && !request.sevdesk.offerCreatedAt))
								"
							>
								<feather-icon icon="CheckIcon" class="icon" />
								<span class="text-left ml-1">
									{{ request.sevdesk && request.sevdesk.offerId ? 'Angebot aktualisieren' : 'Angebot erstellen' }}
								</span>
							</b-button>
							<div
								v-if="!creatingOffer && request.sevdesk && request.costs.updatedAt < request.sevdesk.offerCreatedAt"
								class="text-center"
							>
								Keine neuen Änderungen
							</div>
							<b-button variant="secondary" class="grey-button shadow-none ml-2" @click="closeModal" v-if="!creatingOffer">
								Abbrechen
							</b-button>
							<b-dropdown variant="link" no-caret v-if="!creatingOffer">
								<template #button-content>
									<feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
								</template>

								<b-dropdown-item @click="resetValues">
									<feather-icon icon="RotateCcwIcon" />
									<span class="align-middle ml-50">Änderungen zurücksetzen</span>
								</b-dropdown-item>
							</b-dropdown>
						</div>
					</div>
				</b-col>
				<!-- <b-col cols="0" class="right">
					<div>
						<div class="big">{{ formatDate(deadline) }}</div>
						<div>
							<b-input-group class="shadow-none">
								<b-form-input
									id="example-input"
									v-model="value"
									type="text"
									placeholder="YYYY-MM-DD"
									autocomplete="off"
								></b-form-input>
								<b-input-group-append>
									<b-form-datepicker
										class="mr-1"
										size="sm"
										:min="minDeadline"
										v-model="deadline"
										button-only
									></b-form-datepicker>
								</b-input-group-append>

								<div class="big">{{ formatDate(deadline) }}</div>
							</b-input-group>
							<b-form-datepicker :min="minDeadline" v-model="deadline" button-only small></b-form-datepicker>
						</div>
						<div class="small">Deadline</div>
					</div>
					<div>
						<div class="big">{{ formatDate(productionStart) }}</div>
						<div class="small">bis</div>
						<div class="big">{{ formatDate(productionEnd) }}</div>
						<div class="small">Produktionszeitraum</div>
					</div>
					<div>
						<div class="big">
							<b-form-select
								class="payment-term-select"
								v-model="paymentTerm"
								:options="paymentTermOptions"
								size="lg"
							></b-form-select>
							<b-dropdown text="Zahlungsfrist" block variant="primary" menu-class="w-100">
								<b-dropdown-item href="#">7 Tage</b-dropdown-item>
								<b-dropdown-item href="#">14 Tage</b-dropdown-item>
								<b-dropdown-item href="#">28 Tage</b-dropdown-item>
							</b-dropdown>
						</div>
						<div class="small">Zahlungsfrist</div>
					</div>
					<div class="blue">
						<b-spinner variant="light" v-if="creatingOffer"></b-spinner>
						<b-button variant="primary" class="blue-button shadow-none" @click="createOffer" v-if="!creatingOffer">
							<feather-icon icon="CheckIcon" class="icon" />
							{{ request.sevdesk && request.sevdesk.offerId ? 'Angebot\naktualisieren' : 'Angebot\nerstellen' }}
						</b-button>
					</div>
				</b-col> -->
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import {
	BButton,
	BModal,
	BRow,
	BCol,
	BFormInput,
	BFormDatepicker,
	BFormTextarea,
	BInputGroup,
	BInputGroupAppend,
	BDropdown,
	BDropdownItem,
	BFormSelect,
	BSpinner,
} from 'bootstrap-vue'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'

import CostVariant from './partials/CostVariant'
// import CostInput from './partials/CostInput'
import PriceInput from './partials/PriceInput'
import AmountInput from './partials/AmountInput'
import SelectInput from './partials/SelectInput'
import FinishRow from './partials/FinishRow.vue'
import AdditionalCosts from './partials/AdditionalCosts.vue'
import MockupDisplay from '../partials/MockupDisplay'
import GlobalAdditionalCosts from './partials/GlobalAdditionalCosts.vue'

import FinishSettings from '@/assets/data/finishSettings'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
	name: 'OfferModal',
	components: {
		BButton,
		BModal,
		BRow,
		BCol,
		BFormInput,
		CostVariant,
		MockupDisplay,
		BFormDatepicker,
		BFormTextarea,
		BInputGroup,
		BInputGroupAppend,
		BDropdown,
		BDropdownItem,
		BFormSelect,
		BSpinner,
		PriceInput,
		AmountInput,
		SelectInput,
		FinishRow,
		AdditionalCosts,
		GlobalAdditionalCosts,
	},
	props: {
		request: { type: Object, required: true },
		disabled: { type: Boolean, required: true },
	},
	data() {
		const now = new Date()
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
		// 15th two months prior
		const minDeadline = new Date(today)

		const productionStart = new Date(today)
		const productionEnd = dayjs(productionEnd).add(7, 'day').format()

		return {
			modal: false,
			productionCosts: 0,
			updatedCalcData: {},
			deadline: minDeadline,
			minDeadline: minDeadline,
			productionStart: productionStart,
			productionEnd: productionEnd,
			paymentTerm: '7',
			paymentTermOptions: [
				{ value: '7', text: '7 Tage' },
				{ value: '14', text: '14 Tage' },
				{ value: '28', text: '28 Tage' },
			],
			creatingOffer: false,
			updated: {},
			showMockup: false,
		}
	},
	computed: {
		...mapGetters({
			isSaving: 'Requests/getSavingState',
		}),
		formattedShippingBoxCount() {
			return this.request.shippingBoxCount || 1
		},
	},
	watch: {
		modal(value) {
			if (value) {
				// if (!this.request.costs || (this.request.costs && !this.request.costs.productionCosts))
				// 	this.$store.dispatch('Requests/calculateCosts', { requestId: this.request._id })
				setTimeout(() => {
					this.showMockup = true
				}, 10)
			}
		},
	},
	setup() {
		const toast = useToast()

		return { toast }
	},
	methods: {
		updateCosts(key, value, cb) {
			console.log('update costs')
			const data = {}
			data[key] = value
			console.log(data)
			this.$store.dispatch('Requests/startSaving')
			this.$store
				.dispatch('Requests/updatePrices', { id: this.request._id, data })
				.then(() => {
					this.$store
						.dispatch('Requests/calculateCosts', { requestId: this.request._id })
						.then(() => {
							this.$store.dispatch('Requests/stopSaving')
							if (cb) cb()
						})
						.catch((err) => {
							console.error('Oh no!')
							console.error(err)
							this.$store.dispatch('Requests/stopSaving')
							if (cb) cb()
						})
				})
				.catch((err) => {
					console.error('Oh no!')
					console.error(err)
					this.$store.dispatch('Requests/stopSaving')
					if (cb) cb()
				})
		},
		getConfigWithSingleConfig(config, finishIdx) {
			const modified = { ...config }

			// replace finishes
			const newFinishes = {}
			newFinishes[0] = { ...modified.finishes[finishIdx] }
			modified.finishes = newFinishes

			// replace variant finishes
			const variants = { ...modified.variants }
			for (let vKey in variants) {
				const variant = { ...variants[vKey] }

				const newVariantFinishes = {}
				newVariantFinishes[0] = { ...variant.finishes[finishIdx] }
				variant.finishes = newVariantFinishes
				variant.hexColor = '#fff'
				variant.extractedHexColor = '#fff'
				variant.color = 'white'
				variants[vKey] = variant
			}
			modified.variants = { ...variants }

			return modified
		},
		formatDate(date) {
			console.log(date)
			return dayjs(date).format('DD.MM.YY')
		},
		createOffer() {
			this.creatingOffer = true

			// const that = this
			// setTimeout(function () {
			// 	console.log('stop creating')
			// 	that.creatingOffer = false
			// }, 5000)

			this.$store
				.dispatch('Requests/createOffer', { requestId: this.request._id })
				.then(() => {
					this.creatingOffer = false
					this.closeModal()
				})
				.catch((err) => {
					this.creatingOffer = false

					this.toast({
						component: ToastificationContent,
						props: {
							title: err.toString(),
							icon: 'AlertTriangleIcon',
							variant: 'danger',
						},
					})
				})
		},
		resetValues() {
			this.creatingOffer = true

			this.$store
				.dispatch('Requests/resetValues', { requestId: this.request._id })
				.then(() => {
					this.creatingOffer = false
				})
				.catch((err) => {
					this.creatingOffer = false

					this.toast({
						component: ToastificationContent,
						props: {
							title: err.toString(),
							icon: 'AlertTriangleIcon',
							variant: 'danger',
						},
					})
				})
		},
		closeModal() {
			this.showMockup = false
			this.modal = false
		},
	},
}
</script>

<style lang="sass">
#offer-modal
	.modal-dialog
		height: 100vh
		max-width: 100%
		margin: 0
		top: 0
		bottom: 0
		left: 0
		right: 0
		height: 100vh
		max-height: 100vh
		display: flex
		position: fixed
		z-index: 100000
		.modal-content
			height: 100vh
			max-height: 100vh
	.modal-body
		padding: 0
</style>

<style lang="sass" scoped>
button.disabled
	opacity: 0.15
.saving-overlay
	position: absolute
	top: 0
	bottom: 0
	left: 0
	right: 0
	background: rgba(0,0,0,0.5)
	color: white
	z-index: 10
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	font-size: 50px
	font-weight: 700
	.small
		font-size: 40px
		font-weight: 700

.divider
	height: 1px
	background-color: #EBE9F1
	margin: 20px 20px
	// margin-left: 205px
.left
	position: relative
	contain: content
	height: 100%
	.total-box
		position: fixed
		z-index: 10
		bottom: 0
		left: 0
		right: 0
		display: flex
		justify-content: flex-end
		align-items: center
		padding: 10px 0 10px 15px
		border-top: 1px solid #EBE9F1
		background: white
		height: 80px
		.big
			font-size: 40px
			font-weight: 700
		.small
			font-size: 14px

		.action-button-wrapper
			// width: 320px
			width: 401px
			// width: 357px
			.create-offer-btn
				line-height: 1.4em
				padding: 8px 20px
				.icon
					height: 34px
					width: 34px

		.additional-costs
			margin-right: auto

	.configs
		overflow-y: auto
		height: calc(100% - 80px)
		color: #6E6B7B
		.config
			min-height: 200px
			border-bottom: 4px solid #EBE9F1
			&:last-child
				border-bottom: none
			.variant
				display: flex
				flex-direction: column
				margin-bottom: 20px
				.divider
					height: 1px
					background-color: #EBE9F1
					margin: 20px 20px 0
					// margin-left: 205px
				&:last-child
					.divider
						display: none
			.conf-label
				flex-shrink: 0
				width: 150px
				margin-left: 20px
				margin-top: 20px
				margin-bottom: 20px
				text-align: center
				background: rgba(0,0,0,0.1)
				border-radius: 4px
				.text
					.label
						font-size: 16px
						font-weight: 300
					.number
						font-size: 70px
						font-weight: 700
			.conf-costs
				margin: 40px 0 20px
				flex-grow: 1
				.extra-costs
					margin: 0 20px 0 20px
					&.stick, &.misc
						margin-left: 0px
				.finish-header
					margin-bottom: 12px
					&.sub
						margin-top: 24px
					h5
						margin: 0
						font-size: 22px
						.light
							font-weight: 400
			.motives-header
				margin-bottom: 12px
				margin-left: 20px
				h5
					margin: 0
					font-size: 22px
			.finish-costs-wrapper
				.finish-costs
					margin: 0 20px 20px 0px
					display: flex
					flex-direction: column

				.finish-costs-loop
					&:first-child
						.finish-header
							margin-top: 0px !important

.right
	border-left: 1px solid #EBE9F1
	display: flex
	flex-direction: column
	>div
		height: 25%
		display: flex
		align-items: center
		justify-content: center
		flex-direction: column
		border-bottom: 1px solid #EBE9F1
		padding: 20px 0
		&.blue
			flex-shrink: 0
			flex-grow: 0
			padding: 0
			background-color: #7367F0
			.blue-button
				border-radius: 0 0 0.358rem
				background-color: #7367F0
				color: white
				display: flex
				flex-direction: column
				align-items: center
				justify-content: center
				height: 100%
				width: 100%
				font-weight: 500
				font-size: 24px
				line-height: 1.3em
				.icon
					width: 50px
					height: 50px
		&:last-child
			border-bottom: none
		.big
			font-size: 30px
			font-weight: 700
		.small
			font-size: 14px

		.payment-term-select
			font-weight: 700
</style>
