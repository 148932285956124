import { render, staticRenderFns } from "./ChangeFileModal.vue?vue&type=template&id=77836bf3&scoped=true&"
import script from "./ChangeFileModal.vue?vue&type=script&lang=js&"
export * from "./ChangeFileModal.vue?vue&type=script&lang=js&"
import style0 from "./ChangeFileModal.vue?vue&type=style&index=0&id=77836bf3&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77836bf3",
  null
  
)

export default component.exports