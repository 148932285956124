var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-center wrapper"},[(_vm.currentSide === 'front')?_c('SVGFront',{attrs:{"mockupSide":"front","mainColor":_vm.variant.extractedHexColor,"secColor":_vm.variant.extractedHexColor,"currentStep":"admin","allFinishes":_vm.conf.finishes,"files":_vm.files,"mockupType":_vm.conf && _vm.conf.ref && _vm.conf.ref.productType}}):_vm._e(),(_vm.currentSide === 'back')?_c('SVGBack',{attrs:{"mockupSide":"back","mainColor":_vm.variant.extractedHexColor,"secColor":_vm.variant.extractedHexColor,"currentStep":"admin","allFinishes":_vm.conf.finishes,"files":_vm.files,"mockupType":_vm.conf && _vm.conf.ref && _vm.conf.ref.productType}}):_vm._e(),(_vm.currentSide === 'left')?_c('SVGLeft',{attrs:{"mockupSide":"left","mainColor":_vm.variant.extractedHexColor,"secColor":_vm.variant.extractedHexColor,"currentStep":"admin","allFinishes":_vm.conf.finishes,"files":_vm.files,"mockupType":_vm.conf && _vm.conf.ref && _vm.conf.ref.productType}}):_vm._e(),(_vm.currentSide === 'right')?_c('SVGRight',{attrs:{"mockupSide":"right","mainColor":_vm.variant.extractedHexColor,"secColor":_vm.variant.extractedHexColor,"currentStep":"admin","allFinishes":_vm.conf.finishes,"files":_vm.files,"mockupType":_vm.conf && _vm.conf.ref && _vm.conf.ref.productType}}):_vm._e(),_c('div',{class:[
				'my-1',
				'side-switch-wrapper',
				'd-flex',
				'align-items-center',
				'justify-content-center',
				'flex-row',
				_vm.fixedButtons && 'fixed' ]},[(_vm.hasSide('front'))?_c('div',{class:[{ active: _vm.currentSide === 'front' }, 'side-switch'],on:{"click":function($event){_vm.currentSide = 'front'}}},[_vm._v(" Vorne ")]):_vm._e(),(_vm.hasSide('back'))?_c('div',{class:[{ active: _vm.currentSide === 'back' }, 'side-switch'],on:{"click":function($event){_vm.currentSide = 'back'}}},[_vm._v(" Hinten ")]):_vm._e(),(_vm.hasSide('left') || _vm.hasPosition('shoulder-sleeve-left-custom'))?_c('div',{class:[{ active: _vm.currentSide === 'left' }, 'side-switch'],on:{"click":function($event){_vm.currentSide = 'left'}}},[_vm._v(" Ärmel links ")]):_vm._e(),(_vm.hasSide('right') || _vm.hasPosition('shoulder-sleeve-right-custom'))?_c('div',{class:[{ active: _vm.currentSide === 'right' }, 'side-switch'],on:{"click":function($event){_vm.currentSide = 'right'}}},[_vm._v(" Ärmel rechts ")]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }