<template>
	<div>
		<b-button variant="warning" block v-b-modal.change-configurator-modal :disabled="disabled"> Änderung vorschlagen </b-button>

		<b-modal id="change-configurator-modal" title="ChangeConfiguratorModal">
			<p class="my-4">ChangeConfiguratorModal</p>
		</b-modal>
	</div>
</template>

<script>
import { BButton, BModal } from 'bootstrap-vue'

export default {
	name: 'ChangeConfiguratorModal',
	components: { BButton, BModal },
	props: {
		disabled: { type: Boolean, default: false },
	},
}
</script>

<style lang="sass" scoped>
button.disabled
	opacity: 0.15
</style>