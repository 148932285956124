<template>
	<div>
		<b-button
			block
			@click="modal = !modal"
			variant="primary"
			:disabled="
				request.status !== 'INVOICE_CREATED' ||
				!request.payment ||
				(request.payment && request.payment.paymentService !== 'bank-transfer')
			"
			>Zahlung erfassen</b-button
		>
		<!-- <div class="button" @click="modal = !modal">Farbvorschlag</div> -->

		<b-modal
			id="set-payment-modal"
			title="Zahlung erfassen"
			v-model="modal"
			ok-title="Zahlung erfassen"
			cancel-title="Abbrechen"
			:busy="saving"
			@ok="(ev) => markAsPayed(ev)"
			size="md"
		>
			<b-row>
				<b-col cols="12" class="my-2" v-if="request.sevdesk && request.payment">
					Möchtest du das Projekt wirklich als bezahlt markieren? <br /><br />
					Stelle sicher dass der Kunde <b>{{ totalCosts }}€</b> überwiesen hat. <br /><br />
					<a :href="request.sevdesk.invoicePdf" target="_blank">Rechnung {{ request.sevdesk.invoiceNumber }}</a>
				</b-col>
			</b-row>
			<div></div>
		</b-modal>
	</div>
</template>

<script>
import { BRow, BCol, BButton, BModal } from 'bootstrap-vue'

import { setBankTransferPayment } from '@/api/requests.api'

export default {
	name: 'SetPaymentModal',
	components: { BRow, BCol, BButton, BModal },
	props: {
		request: { type: Object, required: true },
	},
	mounted() {
		console.log(this.request)
	},
	computed: {
		totalCosts() {
			return (this.request.costs.costsTotal + this.request.costs.costsTotal * 0.19).toFixed(2)
		},
	},
	data() {
		return {
			modal: false,
			saving: false,
		}
	},
	methods: {
		markAsPayed(ev) {
			ev.preventDefault()
			this.saving = true
			setBankTransferPayment(this.request._id)
				.then(() => {
					this.saving = false
					this.modal = false
				})
				.catch((err) => {
					console.log(err)
					this.saving = false
				})
		},
	},
}
</script>

<style lang="sass" scoped>
button.disabled
	opacity: 0.15
</style>