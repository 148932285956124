<template>
	<div>
		<div class="variant-settings">
			<div class="inner-wrapper">
				<div class="mockup">
					<h5 class="mb-2">Textilfarbe {{ parseInt(variantIdx) + 1 }}</h5>
					<MockupDisplay v-if="showMockup" :variant="variant" :conf="config" :files="requestFiles" />
				</div>
				<div class="main-info">
					<h5 class="info-header">Textil</h5>

					<div class="label">Name:</div>
					<div class="name">{{ config.name }}</div>
					<div class="label">Farbe:</div>
					<div class="color">{{ variant.color }}</div>
					<div class="label">Shop:</div>
					<div class="shop">
						{{ config.shop === 'l-shop' ? 'L-Shop' : config.shop === 'stanley-stella' ? 'Stanley/Stella' : 'n/a' }}
					</div>
					<div class="label">Artikelnummer:</div>
					<div class="name">{{ config.externalId }}</div>
					<div class="label">Menge:</div>
					<div class="total-amount">
						<div class="value">{{ variantAmount() }} Stück</div>
					</div>
				</div>
				<div class="costs" v-if="request.costs">
					<div class="main-costs">
						<div class="finish-header">
							<h5>Gesamtkosten <span class="light">pro Textil</span></h5>
						</div>
						<PriceInput
							label="Einkaufspreis"
							:value="request.costs.productionCosts[configIdx].variants[variantIdx].purchasingPricePerTextile || 0"
							:updateKey="`configurations.${configIdx}.variants.${variantIdx}.purchasingPrice`"
							:updateFn="updateCosts"
							:editable="true"
							small
						/>
						<PriceInput
							label="Textil"
							:value="request.costs.productionCosts[configIdx].variants[variantIdx].pricePerTextile || 0"
							small
							:tooltip="tooltipText('textile')"
							:tooltipKey="configIdx + variantIdx + 'textile'"
						/>
						<PriceInput
							label="Print"
							:value="request.costs.productionCosts[configIdx].variants[variantIdx].printPerTextile || 0"
							small
						/>
						<PriceInput
							label="Stick"
							:value="request.costs.productionCosts[configIdx].variants[variantIdx].stickPerTextile || 0"
							small
						/>
						<!-- <PriceInput
							label="Nähservice"
							:value="request.costs.productionCosts[configIdx].variants[variantIdx].sewServicePerTextile"
							small
						/> -->
						<PriceInput
							label="Nebenkosten"
							:value="request.costs.productionCosts[configIdx].variants[variantIdx].extraPerTextile || 0"
							small
							:tooltip="tooltipText('extraCosts')"
							:tooltipKey="configIdx + variantIdx + 'extraCosts'"
						/>
						<PriceInput
							label="Puffer %"
							:value="
								request.configurations[this.configIdx].variants[this.variantIdx].puffer ||
								request.configurations[this.configIdx].variants[this.variantIdx].puffer === 0
									? request.configurations[this.configIdx].variants[this.variantIdx].puffer
									: 2
							"
							:updateKey="`configurations.${configIdx}.variants.${variantIdx}.puffer`"
							:updateFn="updateCosts"
							:editable="true"
							currency="%"
							small
						/>
						<PriceInput
							:label="`Puffer (+ ${this.request.configurations[this.configIdx].variants[this.variantIdx].puffer || 2} %)`"
							:value="request.costs.productionCosts[configIdx].variants[variantIdx].puffer || 0"
							small
						/>
						<!-- <PriceInput
							:label="`Versandrabatt (${request.shippingType || request.customer.shippingType})`"
							:value="request.costs.productionCosts[configIdx].variants[variantIdx].shippingDiscount || 0"
							small
						/> -->
					</div>
				</div>
			</div>
			<div class="d-flex">
				<div class="finish-costs d-flex flex-column">
					<div class="finish-header">
						<h5>Motive</h5>
					</div>
					<div
						v-for="(finishCosts, finishCostsIdx) in request.costs.productionCosts[configIdx].variants[variantIdx].finishes"
						:key="finishCostsIdx"
					>
						<VariantFinishRow
							:variant="variant"
							:variantIdx="parseInt(variantIdx)"
							:config="config"
							:configIdx="parseInt(configIdx)"
							:finishCosts="finishCosts"
							:finishCostsIdx="parseInt(finishCostsIdx)"
							:updateCosts="updateCosts"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="variant-total">
			<span class="small">
				{{ request.costs.productionCosts[configIdx].variants[variantIdx].costsPerTextile.toFixed(2) }}€ pro Textil x
				{{ variantAmount(variant) }} Stück
			</span>
			{{ request.costs.productionCosts[configIdx].variants[variantIdx].costsTotal.toFixed(2) }}€
			<div class="raw-margin">
				<!-- <small
					>Roh-Marge: {{ request.costs.productionCosts[configIdx].variants[variantIdx].costsTotal }}€ -
					{{
						(variantAmount() + request.costs.productionCosts[configIdx].surplus.amount) *
						request.costs.productionCosts[configIdx].variants[variantIdx].purchasingPricePerTextile
					}}€ =
				</small> -->
				<small class="small">Roh-Marge: {{ calcRawMargin() }}€</small>
			</div>
		</div>
		<div class="actions">
			<b-button variant="success" :disabled="saving" size="sm" @click="saveUpdatedCalc" v-if="Object.keys(updated).length > 0">
				<span v-if="saving">
					<b-spinner style="margin-right: 4px" small variant="light" label="Spinning"></b-spinner> Bitte warten..
				</span>
				<span v-else>Speichern</span>
			</b-button>
			<b-button
				class="ml-2"
				:disabled="saving"
				variant="warning"
				size="sm"
				@click="resetVariantCalc"
				v-if="Object.keys(updated).length > 0"
				>Zurücksetzen</b-button
			>
		</div>
	</div>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import PriceInput from './PriceInput'
import AmountInput from './AmountInput'
import SelectInput from './SelectInput'
import VariantFinishRow from './VariantFinishRow'
import MockupDisplay from '../../partials/MockupDisplay'
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'CostVariant',
	components: { BButton, BSpinner, AmountInput, SelectInput, MockupDisplay, PriceInput, VariantFinishRow },
	props: {
		request: { type: Object, required: true },
		config: { type: Object, required: true },
		configIdx: { type: Number, required: true },
		variant: { type: Object, required: true },
		variantIdx: { type: Number, required: true },
	},
	computed: {
		...mapFields('Requests', {
			requestFiles: 'files',
		}),
	},
	data() {
		return {
			showExtraCosts: false,
			showMockup: false,
			updated: {},
			updatedCostsTotal: null,
			updatedCostsPerTextile: null,
			saving: false,
		}
	},
	mounted() {
		setTimeout(() => {
			this.showMockup = true
		}, 10)
	},
	methods: {
		formattedPrice(price) {
			return parseFloat(Math.round(price * 100) / 100).toFixed(2)
		},
		variantAmount() {
			return this.variant.sizes.reduce((acc, cur) => acc + cur.amount, 0)
		},
		totalAmount() {
			let amount = 0
			this.request.configurations.forEach((config) => {
				for (let vKey in config.variants) {
					const variant = config.variants[vKey]
					amount += variant.sizes.reduce((acc, cur) => acc + cur.amount, 0)
				}
			})
			return amount
		},
		updateCosts(key, value, cb) {
			console.log('update costs (CostVariant)')
			const data = {}
			data[key] = value
			console.log(data)

			this.$store.dispatch('Requests/startSaving')
			this.$store
				.dispatch('Requests/updatePrices', { id: this.request._id, data })
				.then(() => {
					this.$store
						.dispatch('Requests/calculateCosts', { requestId: this.request._id })
						.then(() => {
							this.$store.dispatch('Requests/stopSaving')
							if (cb) cb()
						})
						.catch((err) => {
							console.error('Oh no!')
							console.error(err)
							this.$store.dispatch('Requests/stopSaving')
							if (cb) cb()
						})
				})
				.catch((err) => {
					console.error('Oh no!')
					console.error(err)
					this.$store.dispatch('Requests/stopSaving')
					if (cb) cb()
				})
		},
		resetVariantCalc() {
			this.updated = {}
			this.updatedCostsPerTextile = null
			this.updatedCostsTotal = null
		},
		saveUpdatedCalc() {
			this.saving = true
			this.$store
				.dispatch('Requests/updateCosts', {
					data: this.updated,
					requestId: this.request._id,
					configIdx: this.configIdx,
					variantIdx: this.variantIdx,
				})
				.then(() => {
					this.resetVariantCalc()
					this.saving = false
				})
		},
		resetOriginalValues() {
			this.$store.dispatch('Requests/calculateVariantCosts', {
				requestId: this.request._id,
				configIdx: this.configIdx,
				variantIdx: this.variantIdx,
			})
		},
		getConfigWithSingleConfig(config, finishIdx) {
			const modified = { ...config }

			// replace finishes
			const newFinishes = {}
			newFinishes[0] = { ...modified.finishes[finishIdx] }
			modified.finishes = newFinishes

			// replace variant finishes
			const variants = { ...modified.variants }
			for (let vKey in variants) {
				const variant = { ...variants[vKey] }

				const newVariantFinishes = {}
				newVariantFinishes[0] = { ...variant.finishes[finishIdx] }
				variant.finishes = newVariantFinishes
				variants[vKey] = variant
			}
			modified.variants = { ...variants }

			return modified
		},
		calcRawMargin() {
			const totalPrice = this.request.costs.productionCosts[this.configIdx].variants[this.variantIdx].costsTotal
			const amount = this.variantAmount()
			const surplusAmount = this.request.costs.productionCosts[this.configIdx].surplus.amount
			const purchasingPrice = this.request.costs.productionCosts[this.configIdx].variants[this.variantIdx].purchasingPricePerTextile

			return (totalPrice - (amount + surplusAmount) * purchasingPrice).toFixed(2)
		},
		tooltipText(type) {
			let text = ``
			if (type === 'textile') {
				const prodCosts = this.request.costs.productionCosts[this.configIdx]
				text += `<div class="d-flex justify-content-between tooltip-row"><div class="mr-3">Einkaufspreis:</div> <div>${this.formattedPrice(
					prodCosts.variants[this.variantIdx].purchasingPricePerTextile
				)}€</div></div>`
				text += `<div class="d-flex justify-content-between"><div class="mr-3">Multiplikator:</div> <div>${
					prodCosts.variants[this.variantIdx].pricePerTextileMultiplier
				}x</div></div>`
			} else if (type === 'extraCosts') {
				const variantAmount = this.variantAmount()
				const totalAmount = this.totalAmount()
				const prodCosts = this.request.costs.productionCosts[this.configIdx]

				// Siebeinrichtung
				let finishCosts = 0
				for (let fKey in prodCosts.finishes) {
					const finish = prodCosts.finishes[fKey]
					if (finish.printScreenSetup) {
						finishCosts += finish.printScreenSetup.costs / variantAmount
					}
				}
				if (finishCosts > 0)
					text += `<div class="d-flex justify-content-between tooltip-row"><div class="mr-3">Siebeinrichtung:</div> <div>${this.formattedPrice(
						finishCosts
					)}€</div></div>`
				// Palettenwechsel
				if (prodCosts.paletteChange.costs > 0) {
					text += `<div class="d-flex justify-content-between tooltip-row"><div class="mr-3">Palettenwechsel:</div> <div>${this.formattedPrice(
						prodCosts.paletteChange.costs / variantAmount
					)}€</div></div>`
				}
				// Stick (Standard)
				if (prodCosts.stickFile.costs > 0) {
					text += `<div class="d-flex justify-content-between tooltip-row"><div class="mr-3">Stick (Standard):</div> <div>${this.formattedPrice(
						prodCosts.stickFile.costs / variantAmount
					)}€</div></div>`
				}
				// Stickdatei
				if (prodCosts.stickStandard.costs > 0) {
					text += `<div class="d-flex justify-content-between tooltip-row"><div class="mr-3">Stickdatei:</div> <div>${this.formattedPrice(
						prodCosts.stickStandard.costs / variantAmount
					)}€</div></div>`
				}
				// Rahmenwechsel
				if (prodCosts.frameChange.costs > 0) {
					text += `<div class="d-flex justify-content-between tooltip-row"><div class="mr-3">Rahmenwechsel:</div> <div>${this.formattedPrice(
						prodCosts.frameChange.costs / variantAmount
					)}€</div></div>`
				}
				// Farbwechsel
				if (prodCosts.colorChange.costs > 0) {
					text += `<div class="d-flex justify-content-between tooltip-row"><div class="mr-3">Farbwechsel:</div> <div>${this.formattedPrice(
						prodCosts.colorChange.costs / variantAmount
					)}€</div></div>`
				}
				// Überschuss
				if (prodCosts.surplus.costs > 0) {
					text += `<div class="d-flex justify-content-between tooltip-row"><div class="mr-3">Überschuss:</div> <div>${this.formattedPrice(
						prodCosts.surplus.costs / variantAmount
					)}€</div></div>`
				}
				// Extrakosten
				if (prodCosts.extraCosts > 0) {
					text += `<div class="d-flex justify-content-between tooltip-row"><div class="mr-3">Extrakosten:</div> <div>${this.formattedPrice(
						prodCosts.extraCosts / variantAmount
					)}€</div></div>`
				}
				// Versand
				const shippingCosts = this.formattedPrice(this.request.costs.shippingCosts / totalAmount)
				text += `<div class="d-flex justify-content-between"><div class="mr-3">Versand:</div> <div>${shippingCosts}€</div></div>`
			}
			return text
		},
	},
}
</script>

<style lang="sass" scoped>
.variant-settings
	overflow-x: auto
	// margin-left: 195px
	.inner-wrapper
		display: flex
		flex-direction: row
		justify-content: flex-start
		align-items: flex-start
		// width: 2000px
		.mockup, .costs, .settings
			flex-shrink: 0
			flex-grow: 0
		.mockup
			padding-top: 20px
			margin-left: 20px
			width: 150px
			width: 300px
			text-align: left

			h5
				// text-align: left
				font-size: 22px
		.main-info
			padding: 20px 20px 20px 60px
			width: 300px
			.info-header
				font-size: 22px
				margin-bottom: 18px
			.label
				margin-top: 10px
				font-size: 12px
				font-weight: 500
			.name
				font-weight: 300
				font-size: 18px
			.color
				font-weight: 300
				font-size: 18px
			.shop
				font-weight: 300
				font-size: 18px
			.total-amount
				.heading
					font-weight: 300
					font-size: 12px
				.value
					font-weight: 300
					font-size: 18px
		.extra-costs-wrapper
			margin-right: 20px

		.settings
			margin: 0 100px
		.costs
			display: flex
			flex-direction: row
			justify-content: flex-end
			align-items: flex-start
			padding: 20px 20px 20px 60px

			.main-costs
				margin-right: 20px
				.finish-header
					margin-bottom: 12px
					h5
						margin: 0
						font-size: 22px
						.light
							font-weight: 400
			.extra-costs
				.heading
					font-weight: 500
	.finish-costs
		margin: 40px 0
		.finish-header
			margin-bottom: 12px
			margin-left: 20px
			h5
				margin: 0
				font-size: 22px

.variant-total
	width: 100%
	max-width: 1090px
	text-align: right
	font-size: 25px
	font-weight: 700
	// margin-top: 20px
	padding-right: 20px
	display: flex
	flex-direction: column
	justify-content: flex-end
	justify-content: center
	align-items: center
	align-items: flex-end
	.small
		font-size: 14px
		font-weight: 300
		// margin-right: 40px
		margin-bottom: 4px
.actions
	display: flex
	align-items: center
	justify-content: flex-end
	// padding-right: 20px
	// margin-top: 20px
</style>
