<template>
	<div class="variant-finish-row d-flex flex-row">
		<div class="data-col finish-info d-flex flex-row align-items-center justify-content-between">
			<div class="finish-header">
				<h5>Motiv {{ finishCostsIdx + 1 }}</h5>
			</div>
			<div class="main-info">
				<div class="text-capitalize">{{ resolveLabel(config.finishes[finishCostsIdx].technique, 'technique') }}</div>
				<div v-if="config.finishes[finishCostsIdx].technique === 'print' || config.finishes[finishCostsIdx].technique === 'stick'">
					<div class="d-flex align-items-center">
						{{ variant.finishes[finishCostsIdx].colors.length }} Farbe(n):
						<span
							v-for="(color, colorIdx) in variant.finishes[finishCostsIdx].colors"
							:key="colorIdx"
							:style="`margin-left: 4px; display: inline-block; border: 1px solid grey; border-radius: 50%; width: 15px; height: 15px; background-color: ${
								color.recommendedRgbColor
									? color.recommendedRgbColor
									: color.substituteRgbColor
									? color.substituteRgbColor
									: color.hexColor
							}`"
						></span>
					</div>
					<div>
						{{ resolveLabel(variant.finishes[finishCostsIdx].type, 'colorType') }}
					</div>
				</div>
			</div>
			<!-- <div style="width: 100px">
				<MockupDisplay
					v-if="showMockup"
					:variant="variant"
					:conf="getConfigWithSingleConfig(config, finishCostsIdx)"
					:files="request.files"
					:customCurrentSide="config.finishes[finishCostsIdx].side"
				/>
			</div> -->
		</div>
		<SelectInput
			label="Druck"
			currency="Druck"
			:selection="finishCosts.print.colorRatio || 'lightOnDark'"
			:value="finishCosts.print.costs || 0"
			:updateKey="`configurations.${configIdx}.variants.${variantIdx}.finishes.${finishCostsIdx}.colorRatio`"
			:updateFn="updateCosts"
			:type="'print'"
			v-if="config.finishes[finishCostsIdx].technique === 'print'"
			class="data-col"
		/>
	</div>
</template>

<script>
import SelectInput from './SelectInput'

import FinishLabels from '@/assets/data/finishLabels.js'

export default {
	name: 'VariantFinishRow',
	components: { SelectInput },
	props: {
		finishCosts: { type: Object },
		finishCostsIdx: { type: Number },
		config: { type: Object },
		configIdx: { type: Number },
		variant: { type: Object },
		variantIdx: { type: Number },
		updateCosts: { type: Function },
	},
	methods: {
		resolveLabel(value, type) {
			return FinishLabels[type][value] || 'n/a'
		},
	},
}
</script>

<style lang="sass" scoped>
.variant-finish-row
	background-color: rgba(0,0,0,0.02)
	margin: 10px 0 10px 20px
	border-radius: 4px
	position: relative
	.data-col
		width: 350px
		height: 80px
		margin: 0 10px 0 0px
		padding-right: 10px
		background: none
		position: relative
		border-right: 1px solid rgba(0,0,0,0.1)
		border-radius: 0

		&:last-child
			border: none
			margin-right: 0

		&:first-child
			margin-left: 0

		&.finish-info
			// margin-bottom: 4px
			padding: 8px 10px 8px 8px
			font-weight: 700
			font-size: 12px

			.main-info
				span
					margin: 4px 0

		.finish-header
			position: absolute
			top: -14px
			left: 10px
			h5
				margin: 0
				font-size: 12px
				font-weight: 700
				color: #5e5873
				.light
					font-weight: 400
</style>