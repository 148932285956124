<template>
	<div class="wrapper">
		<div class="label">{{ label }}</div>
		<div class="d-flex justify-content-between align-items-center flex-row cost-row">
			<div class="d-flex justify-content-end align-items-center flex-row cost-row">
				<!-- <span class="currency">{{ currency }}</span> -->
				<div class="mr-1 d-flex justify-content-center align-items-center flex-row" style="width: 180px">
					<b-form-select class="select-input" v-model="formattedSelection" :options="options[type]" size="sm"></b-form-select>
				</div>
			</div>
			<div class="d-flex justify-content-end align-items-center flex-row cost-row">
				<div class="ml-1 d-flex justify-content-center align-items-center flex-row" style="width: 70px">
					<b-form-input
						min="0"
						type="number"
						class="text-input"
						v-model="formattedValue"
						size="sm"
						:disabled="true"
					></b-form-input>
				</div>
				<span class="currency">€</span>
			</div>
		</div>
	</div>
</template>

<script>
import { BButton, BModal, BRow, BCol, BFormInput, BFormSelect } from 'bootstrap-vue'

export default {
	name: 'SelectInput',
	components: { BButton, BModal, BRow, BCol, BFormInput, BFormSelect },
	props: {
		label: { type: String, required: true },
		value: { type: Number, required: true },
		valueKey: { type: String, required: false },
		finishIdx: { type: Number, required: false },
		updateFn: { type: Function, required: false },
		updateKey: { type: String, required: false },
		updated: { type: Object, required: false },
		currency: { type: String, default: 'Stück' },
		selection: { type: String, required: true },
		type: { type: String, default: 'printScreenSetup' },
	},
	computed: {
		formattedSelection: {
			get() {
				return this.selection
			},
			set(selection) {
				if (selection && this.updateFn) this.updateFn(this.updateKey, selection)
			},
		},
		formattedValue() {
			return parseFloat((Math.round(this.value * 100) / 100).toFixed(2))
		},
	},
	data() {
		return {
			// selection: null,
			options: {
				printScreenSetup: [
					{ value: null, text: 'Bitte auswählen..' },
					{ value: '<A4', text: 'klein (bis DIN A4)' },
					{ value: '<A4-reprint', text: 'klein Nachdruck' },
					{ value: '>A4', text: 'groß (A4-MAX)' },
					{ value: '>A4-reprint', text: 'groß Nachdruck' },
					{ value: 'inside-print', text: 'Innendruck' },
				],
				stickFile: [
					{ value: null, text: 'Bitte auswählen..' },
					{ value: 'small', text: 'klein' },
					{ value: 'middle', text: 'mittel' },
					{ value: 'big', text: 'groß' },
					{ value: 'none', text: 'Stickdatei vorhanden' },
				],
				print: [
					{ value: null, text: 'Bitte auswählen..' },
					{ value: 'lightOnDark', text: 'hell auf dunkel' },
					{ value: 'darkOnLight', text: 'dunkel auf hell' },
				],
			},
		}
	},
}
</script>

<style lang="sass" scoped>
.wrapper
	padding: 8px
	margin-bottom: 4px
	background-color: rgba(0, 0, 0, 0.02)
	border-radius: 4px
	.label
		font-size: 12px
		flex-grow: 1
		font-weight: 500
		margin-bottom: 8px
.cost-row
	margin-bottom: 2px
	.select-input
		border: 2px solid #7367f0 !important
	.text-input
		background: #F1F1F1
		border-color: #F1F1F1
		font-size: 12px
		color: #5E5873
		font-weight: 500
		text-align: center

	.change-button
		z-index: 10
		position: relative
		padding: 3px
		margin-right: -12px
		&:last-child
			margin-right: 0
			margin-left: -12px

	.currency
		opacity: 0.5
		font-size: 14px
		margin-left: 4px
</style>