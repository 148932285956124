<template>
	<div class="wrapper">
		<TaskCheck type="customer" />
		<b-card class="shadow-none mt-2">
			<b-row class="mb-1">
				<b-col><h3>Kundendaten</h3></b-col>
			</b-row>
			<b-row>
				<!-- Right Col: Table -->
				<b-col cols="12" xl="12">
					<table class="mt-2 mt-xl-0 w-100">
						<tr>
							<th class="pb-50">
								<feather-icon icon="UserIcon" class="mr-75" />
								<span class="font-weight-bold">Name</span>
							</th>
							<td class="pb-50">{{ request.customer.firstname }} {{ request.customer.lastname }}</td>
						</tr>
						<tr>
							<th class="pb-50">
								<feather-icon icon="MailIcon" class="mr-75" />
								<span class="font-weight-bold">E-Mail</span>
							</th>
							<td class="pb-50">
								{{ request.customer.email }}
							</td>
						</tr>
						<tr>
							<th class="pb-50">
								<feather-icon icon="PhoneIcon" class="mr-75" />
								<span class="font-weight-bold">Kontakt</span>
							</th>
							<td>
								{{ request.customer.phone }}
							</td>
						</tr>
						<tr>
							<th class="pb-50 pt-2">
								<feather-icon icon="SendIcon" class="mr-75" />
								<span class="font-weight-bold">Versand</span>
							</th>
							<td class="text-capitalize pt-2">
								{{ request.shippingType }}
							</td>
						</tr>
						<tr>
							<th class="pb-50 pt-2">
								<span class="font-weight-bold"
									><b><i>Versandadresse</i></b></span
								>
							</th>
						</tr>
						<tr>
							<th class="pb-50">
								<feather-icon icon="FlagIcon" class="mr-75" />
								<span class="font-weight-bold">Empfänger</span>
							</th>
							<td class="pb-50">
								{{ request.shippingAddress.name }}
							</td>
						</tr>
						<tr v-if="request.shippingAddress.name2">
							<th class="pb-50"></th>
							<td class="pb-50">
								{{ request.shippingAddress.name2 }}
							</td>
						</tr>
						<tr v-if="request.shippingAddress.name3">
							<th class="pb-50"></th>
							<td class="pb-50">
								{{ request.shippingAddress.name3 }}
							</td>
						</tr>
						<tr>
							<th class="pb-50">
								<feather-icon icon="FlagIcon" class="mr-75" />
								<span class="font-weight-bold">Adresse</span>
							</th>
							<td class="pb-50">
								{{ request.shippingAddress.street }}
							</td>
						</tr>
						<tr>
							<th class="pb-50">
								<feather-icon icon="FlagIcon" class="mr-75" />
								<span class="font-weight-bold">Stadt</span>
							</th>
							<td class="pb-50">{{ request.shippingAddress.zip }} {{ request.shippingAddress.city }}</td>
						</tr>
						<tr>
							<th class="pb-50">
								<feather-icon icon="FlagIcon" class="mr-75" />
								<span class="font-weight-bold">Land</span>
							</th>
							<td class="pb-50">
								{{ request.shippingAddress.country.name }}
							</td>
						</tr>

						<tr v-if="request.invoiceAddress">
							<th class="pb-50 pt-2">
								<span class="font-weight-bold"><i>Rechnungsadresse</i></span>
							</th>
						</tr>
						<tr v-if="request.invoiceAddress">
							<th class="pb-50">
								<feather-icon icon="FlagIcon" class="mr-75" />
								<span class="font-weight-bold">Empfänger</span>
							</th>
							<td class="pb-50">
								{{ request.invoiceAddress.name }}
							</td>
						</tr>
						<tr v-if="request.invoiceAddress.name2">
							<th class="pb-50"></th>
							<td class="pb-50">
								{{ request.invoiceAddress.name2 }}
							</td>
						</tr>
						<tr v-if="request.invoiceAddress.name3">
							<th class="pb-50"></th>
							<td class="pb-50">
								{{ request.invoiceAddress.name3 }}
							</td>
						</tr>
						<tr v-if="request.invoiceAddress">
							<th class="pb-50">
								<feather-icon icon="FlagIcon" class="mr-75" />
								<span class="font-weight-bold">Adresse</span>
							</th>
							<td class="pb-50">
								{{ request.invoiceAddress.street }}
							</td>
						</tr>
						<tr v-if="request.invoiceAddress">
							<th class="pb-50">
								<feather-icon icon="FlagIcon" class="mr-75" />
								<span class="font-weight-bold">Stadt</span>
							</th>
							<td class="pb-50">{{ request.invoiceAddress.zip }} {{ request.invoiceAddress.city }}</td>
						</tr>
						<tr v-if="request.invoiceAddress">
							<th class="pb-50">
								<feather-icon icon="FlagIcon" class="mr-75" />
								<span class="font-weight-bold">Land</span>
							</th>
							<td class="pb-50">
								{{ request.invoiceAddress.country.name }}
							</td>
						</tr>

						<tr v-if="request.comment">
							<th class="pt-2">
								<feather-icon icon="MessageSquareIcon" class="mr-75" />
								<span class="font-weight-bold">Kommentar</span>
							</th>
							<td>
								<i>{{ request.comment }}</i>
							</td>
						</tr>
					</table>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>
import { BButton, BRow, BCol, BAvatar, BCard } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'

import TaskCheck from './partials/TaskCheck.vue'

import { avatarText } from '@core/utils/filter'

export default {
	name: 'CustomerInfo',
	components: { BButton, BRow, BCol, BAvatar, BCard, TaskCheck },
	props: {
		request: { type: Object, required: true },
	},
	directives: { Ripple },
	// computed: {
	// 	...mapGetters({
	// 		tasks: 'Requests/getTasks',
	// 	}),
	// },
	setup() {
		return {
			avatarText,
		}
	},
	methods: {
		// getTask(type) {
		// 	const exists = !!this.tasks.find((t) => t.type === type)
		// 	return exists
		// },
		// checkTask(type, variantIdx, finishIdx) {
		// 	const action = this.getTask(type, variantIdx, finishIdx) ? 'Requests/uncheckTask' : 'Requests/checkTask'
		// 	this.$store.dispatch(action, { type, variantIdx: null, finishIdx: null, configurationIdx: null })
		// },
	},
}
</script>

<style lang="sass" scoped>
.wrapper
	height: auto !important
	position: relative
.check-button
	position: absolute
	z-index: 10
	top: 4px
	right: 4px
</style>
