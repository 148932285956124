<template>
	<div>
		<MockupDetailModal :variant="variant" :conf="conf" :files="files" v-if="renderMockup" />
		<MockupDisplay :variant="variant" :conf="conf" :files="files" v-if="renderMockup" />
		<div class="d-flex justify-content-center">
			<b-spinner v-if="!renderMockup"></b-spinner>
		</div>
	</div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import MockupDetailModal from '../modals/MockupDetailModal'
import MockupDisplay from './MockupDisplay'

export default {
	name: 'MockupPreview',
	components: { MockupDetailModal, MockupDisplay, BSpinner },
	props: {
		variant: { type: Object, required: true },
		conf: { type: Object, required: true },
		files: { type: Array, required: true },
	},
	data() {
		return {
			renderMockup: true,
			renderTimeout: null,
		}
	},
	mounted() {
		window.addEventListener('resize', this.onResize)
	},
	destroyed() {
		window.removeEventListener('resize', this.onResize)
	},
	methods: {
		onResize() {
			this.renderMockup = false
			if (this.renderTimeout) clearTimeout(this.renderTimeout)
			this.renderTimeout = setTimeout(() => {
				this.renderMockup = true
			}, 1000)
		},
	},
}
</script>
