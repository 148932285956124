<template>
	<b-card class="shadow-none" ref="messages">
		<div id="messagescreen" ref="messagescreen">
			<div class="inner" ref="inner">
				<div
					:class="[
						'message-row',
						(entry.type === 'customer-message' || entry.userName === 'customer' || entry.customer) && 'customer',
					]"
					v-for="(entry, entryIdx) of sortedTimeline"
					:key="entryIdx"
				>
					<div class="message">
						<div class="meta">
							<span v-if="entry.type === 'customer-message'">
								Nachricht von {{ entry.customer.firstname }} {{ entry.customer.lastname }} (Kunde)
							</span>
							<span v-if="entry.type === 'message'">
								Nachricht von {{ entry.user ? `${entry.user.firstname} ${entry.user.lastname}` : 'werc' }}
							</span>
							<span v-if="entry.type === 'internal-note'">
								Notiz von {{ entry.user ? `${entry.user.firstname} ${entry.user.lastname}` : 'werc' }}
							</span>
							<span v-if="entry.type === 'event' && entry.status"> Status Änderung </span>
							<span v-if="(entry.type === 'event+file' || entry.type === 'offer') && entry.status">
								Status Änderung und Datei-Download
							</span>
							<span>{{ formatDate(entry.createdAt) }} - {{ formatTime(entry.createdAt) }}Uhr</span>
						</div>
						<div v-if="entry.type === 'message' || entry.type === 'internal-note'" class="delete-button">
							<b-button
								variant="flat-danger"
								class="btn-icon rounded-circle"
								size="sm"
								@click="deleteEntry(entry)"
								:disabled="saving"
							>
								<feather-icon icon="Trash2Icon" />
							</b-button>
						</div>
						<div
							class="message-content"
							:class="[
								entry.type === 'internal-note' && 'internal-note',
								entry.type === 'offer' && !entry.active && 'inactive-offer',
							]"
						>
							<a :href="entry.file.url" target="_blank" v-if="entry.file">
								<div
									class="file d-flex justify-content-between align-items-center flex-column"
									v-if="entry.type.match('file') || entry.type === 'offer'"
								>
									<div
										class="img-wrapper"
										:style="{ backgroundImage: `url(${entry.file.url})` }"
										v-if="entry.type === 'file-added'"
									></div>
									<feather-icon icon="SearchIcon" size="24" style="margin-top: 6px" v-else />
									<span class="text-center">{{ entry.file.name }}</span>
									<!-- <span class="text-center mt-1">Angebot</span> -->
								</div>
							</a>
							<!-- prettier-ignore -->
							<div class="message-text">{{ entry.text }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</b-card>
</template>

<script>
import { BFormInput, BFormTextarea, BButton, BRow, BCol, BCard } from 'bootstrap-vue'
import _ from 'lodash'
import dayjs from 'dayjs'

export default {
	name: 'PrintMessageTimeline',
	components: { BFormInput, BFormTextarea, BButton, BRow, BCol, BCard },
	props: {
		request: { type: Object, required: true },
		filter: { type: String, default: null, required: false },
	},
	data() {
		return {
			scrolled: false,
			lastScroll: 0,
			messageText: null,
			noteText: null,
			saving: false,
			timelineFilter: null,
		}
	},
	computed: {
		sortedTimeline() {
			const adminTimeline = this.request.timeline.filter((e) => !e.scope || e.scope === 'admin')
			const timeline =
				this.timelineFilter === null
					? adminTimeline
					: adminTimeline.filter((e) => e.type.match(new RegExp(this.timelineFilter, 'gi')))
			return _.orderBy(timeline, ['createdAt'], ['asc'])
		},
	},
	mounted() {
		this.$refs['messagescreen'].addEventListener('scroll', this.autoScroll)
		if (this.filter) {
			this.timelineFilter = this.filter
		}
	},
	beforeDestroy() {
		this.$refs['messagescreen'].removeEventListener('scroll', this.autoScroll)
	},
	watch: {
		timelineFilter() {
			this.scrolled = false
		},
	},
	methods: {
		formatDate(date) {
			return dayjs(date).format('DD.MM.YYYY')
		},
		formatTime(date) {
			return dayjs(date).format('HH:mm')
		},
	},
}
</script>

<style lang="sass" scoped>
#messagescreen
	// height: 400px
	overflow-y: auto
	.inner
		padding-right: 8px

.message-row
	display: flex
	justify-content: flex-end
	margin: 0 0 40px
	&:first-child
		margin-top: 20px
	&.customer
		justify-content: flex-start

.send-message-btn
	background-color: lightgrey !important
	border-color: lightgrey !important
	color: grey !important
	flex-grow: 0
	flex-shrink: 0

.message
	width: 60%
	font-size: 11px
	display: flex
	flex-direction: column
	position: relative
	.meta
		color: #6E6B7B
		opacity: 0.5
		display: flex
		justify-content: space-between
		margin-bottom: 4px
		font-size: 10px
	.delete-button
		position: absolute
		top: 22px
		right: 4px
		z-index: 10
	.message-content
		background-color: #F5F5F5
		border-radius: 4px 4px 4px 0
		padding: 12px
		position: relative
		display: flex
		justify-content: flex-start
		flex-shrink: 0
		flex-direction: row

		&:after
			content: " "
			position: absolute
			z-index: 10
			bottom: -14px
			left: 0
			border-top: 7px solid #F5F5F5
			border-left: 7px solid #F5F5F5
			border-bottom: 7px solid transparent
			border-right: 7px solid transparent
			height: 0px
			width: 0px

		&.internal-note
			background-color: rgba(255, 204, 0, 0.6)
			&:after
				border-top: 7px solid rgba(255, 204, 0, 0.6)
				border-left: 7px solid rgba(255, 204, 0, 0.6)

		&.inactive-offer
			opacity: 0.3

		.file
			min-width: 60px
			width: 60px
			height: 80px
			border-radius: 4px
			border: 2px solid #D1D1D1
			background-color: #FFFFFF
			margin-right: 10px
			cursor: pointer
			padding: 0 2px
			span
				font-size: 8px

			.img-wrapper
				width: 100%
				height: 60px
				background-size: contain
				background-position: center
				background-repeat: no-repeat

		.message-text
			white-space: pre-wrap
			padding-right: 40px
</style>
