<template>
	<div>
		<div class="button text-right" @click="modal = !modal">
			<feather-icon icon="SearchIcon" />
		</div>

		<b-modal id="mockup-detail-modal" v-model="modal" hide-header ok-only size="xl" scrollable>
			<div class="wrapper">
				<MockupDisplay v-if="showMockup" :variant="variant" :conf="conf" :files="files" :fixedButtons="true" />
			</div>
		</b-modal>
	</div>
</template>

<script>
import { BButton, BModal } from 'bootstrap-vue'

import MockupDisplay from '../partials/MockupDisplay'

export default {
	name: 'MockupDetailModal',
	components: { BButton, BModal, MockupDisplay },
	props: {
		variant: { type: Object, required: true },
		conf: { type: Object, required: true },
		files: { type: Array, required: true },
	},
	data() {
		return {
			modal: false,
			showMockup: false,
		}
	},
	watch: {
		modal(value) {
			if (value)
				setTimeout(() => {
					this.showMockup = true
				}, 10)
			else this.showMockup = false
		},
	},
	methods: {},
}
</script>

<style lang="sass" scoped>
.wrapper
    position: relative
.button
    cursor: pointer
</style>