<template>
	<div
		id="drag-finish"
		:class="{ 'drag-wrapper': true, 'selected-finish': this.selectedFinishId.toString() === this.finish.idx }"
		ref="drag-wrapper"
		:style="{
			width: model.image.width + 'px',
			height: model.image.height + 'px',
			left: model.image.x + 'px',
			top: model.image.y + 'px',
			transform: model.image.transform.translate + ' ' + model.image.transform.rotate,
			zIndex: this.selectedFinishId.toString() === this.finish.idx && 11,
		}"
	>
		<div v-if="showSizeInfo" class="size-rect" :style="{ left: model.image.width / 2 - 65 + 'px' }">
			<span v-if="finish.sizeMeasure === 'width'">Breite: {{ cmSize.width.toFixed(1) }}cm</span>
			<span v-if="finish.sizeMeasure === 'height'">Höhe: {{ cmSize.height.toFixed(1) }}cm</span>
			<!-- {{ cmSize.width.toFixed(1) }}cm x {{ cmSize.height.toFixed(1) }}cm -->
		</div>

		<div v-if="showPositionInfo" class="position-rect" :style="{ left: model.image.width / 2 - 65 + 'px' }">
			X: {{ cmPosition.x.toFixed(1) }}cm, Y: {{ cmPosition.y.toFixed(1) }}cm
		</div>
		<img
			class="image-element"
			:src="image"
			:style="{
				width: model.image.width + 'px',
				height: model.image.height + 'px',
				outline: this.selectedFinishId.toString() === this.finish.idx && currentStep === 'motive' ? '1px dashed white' : 'none',
				'outline-offset': '1px', // does not work in ie
			}"
		/>
		<div
			class="move-rect"
			:style="{
				width: model.image.width + 'px',
				height: model.image.height + 'px',
				//border: this.selectedFinishId.toString() === this.finish.idx && currentStep === 'motive' ? '1px dashed white' : 'none',
				cursor: currentStep !== 'motive' ? 'default' : 'move',
			}"
		></div>
		<div class="resize-btn resize-br" v-if="currentStep === 'motive'">
			<v-icon>mdi-arrow-expand</v-icon>
		</div>
		<!-- <circle
				class="rotate-tr"
				fill="white"
				stroke="#64686f"
				stroke-width="0.5"
				:cx="model.image.x + model.image.width"
				:cy="model.image.y"
				r="3"
			></circle> -->
		<!-- this circle is not in the center of the element after the angle !== 0 -->
		<!-- <div class="g-red-center-circle" v-if="false">
			<div
				class="red-center-circle"
				:style="{
					borderRadius: '50%',
					left: model.image.rotate.cx + 'px',
					top: model.image.rotate.cy + 'px',
					transform: model.image.transform.translate,
				}"
			></div>
		</div> -->
		<!-- this circle is in the correct center, even after the angle !== -->
		<!-- <div class="g-blue-center-circle" v-if="false">
			<div
				class="blue-center-circle"
				:style="{
					borderRadius: '50%',
					left: model.image.rotate.cx + 'px',
					top: model.image.rotate.cy + 'px',
					transform: model.image.transform.translate + ' ' + model.image.transform.rotate,
				}"
			></div>
		</div> -->
	</div>
</template>

<script>
import * as d3 from 'd3'

import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import finishSettings from '@/assets/data/finishSettings.js'

const console = {
	log() {},
	error() {},
}

export default {
	name: 'DragFinish',
	props: {
		image: { type: String },
		width: { type: Number },
		height: { type: Number },
		initX: { type: Number },
		initY: { type: Number },
		finish: { type: Object },
		mockupSide: { type: String },
		mockupType: {
			type: String,
		},
		allFinishes: { type: Object },
		currentStep: { type: String },
		files: { type: Array },
	},
	computed: {
		// ...mapFields('ConfiguratorDisplay', {
		// currentStep: 'currentStep', // @INFO: moved to props
		// sizeWarning: 'sizeWarning', // @INFO: set to fixed data value
		// }),
		...mapFields('Configurator', {
			// allFinishes: 'finishes', // @INFO: moved to props
			// selectedFinishId: 'selectedFinishId', // @INFO: moved to computed function
			// selectedProduct: 'product', // @INFO: not needed
		}),
		selectedFinishId() {
			return 0
		},
		printAreaSizes() {
			return finishSettings.printAreaSizes
		},
		referenceDistances() {
			return finishSettings.referenceDistances
		},
	},
	data() {
		return {
			vm: {},
			model: {
				image: {
					url: 'http://i.imgur.com/hSqdOZI.jpg',
					width: 100,
					height: 100,
					x: 0,
					y: 0,
					rotate: {
						angle: 0,
						cx: 0,
						cy: 0,
					},
					translate: {
						x: 0,
						y: 0,
					},
					transform: {
						rotate: 'rotate(0)',
						translate: 'translate(1px,1px)',
					},
				},
			},
			minWidth: null,
			minHeight: null,
			tPos: null,
			elemCenter: null,
			target: null,
			targetClass: null,
			rotateHandleStartPos: null,
			printAreaLeft: 0,
			printAreaRight: 0,
			printAreaTop: 0,
			printAreaBottom: 0,
			blockedAreas: [],
			blockedAreaEnteredSide: 'none',
			svgLeft: 0,
			svgTop: 0,
			svgBottom: 0,
			svgRight: 0,
			spanned: false,
			cmSize: { width: 0, height: 0 },
			cmPosition: { x: 0, y: 0 },
			showSizeInfo: false,
			showPositionInfo: false,
			REF_CM_TO_PIXEL: 0,
			sizeWarning: false,
		}
	},
	watch: {
		allFinishes() {
			if (this.finish.idx === this.selectedFinishId.toString()) {
				this.showSizeInfo = true
				// update size and keep aspect ratio
				const { finishWidth, finishHeight } = this.keepAspectRatio(this.finish.size['width'], this.finish.size['height'])

				var updatedCoordinates = this.getImageUpdatedCoordinates()

				const diffX = updatedCoordinates.width - finishWidth
				const diffY = updatedCoordinates.height - finishHeight

				const x = updatedCoordinates.x + diffX / 2
				const y = updatedCoordinates.y + diffY / 2

				// check if finish is now outside of the printable area, if so show warning
				this.validatePosition(finishWidth, finishHeight, x, y)

				// check if image is bigger than min values, if so do not resize
				if (finishWidth > this.minWidth && finishHeight > this.minHeight) {
					// updates the image object model with the new coordinates values
					this.updateImageCoordinates(finishWidth, finishHeight, x, y)
					this.elemCenter = this.getElementCenter()
					this.updateImageRotateCoordinates(null, this.elemCenter.x, this.elemCenter.y)
				}
				this.showSizeInfo = false
			}
		},
	},
	created() {
		const that = this
		// this is used to update the custom positioning from outside
		// window.eventBus.$on('update-init-coords', that.updateInitCoords)
	},
	mounted() {
		// get print area borders
		const printAreaRect = this.$parent.$refs['mockupSvg'].$refs['print-area-wrapper'].getBoundingClientRect()
		const mockupSvgRect = this.$parent.$refs['svgContainer'].getBoundingClientRect()

		this.svgLeft = mockupSvgRect.left
		this.svgTop = mockupSvgRect.top
		this.svgRight = mockupSvgRect.left + mockupSvgRect.width
		this.svgBottom = mockupSvgRect.top + mockupSvgRect.height

		this.printAreaLeft = printAreaRect.left - this.svgLeft
		this.printAreaTop = printAreaRect.top - this.svgTop
		this.printAreaRight = this.printAreaLeft + printAreaRect.width
		this.printAreaBottom = this.printAreaTop + printAreaRect.height

		// blocked areas
		let refBlockedArea = this.$parent.$refs['mockupSvg'].$refs['blocked-area']
		if (refBlockedArea && !refBlockedArea[0]) {
			// only one blocked area, transform to array
			refBlockedArea = [refBlockedArea]
		}

		if (refBlockedArea) {
			refBlockedArea.forEach((ba) => {
				const baRect = ba.getBoundingClientRect()
				this.blockedAreas.push({
					top: baRect.top - this.svgTop,
					left: baRect.left - this.svgLeft,
					right: baRect.left - this.svgLeft + baRect.width,
					bottom: baRect.top - this.svgTop + baRect.height,
				})
			})
		}

		// get size reference
		// #1 print area width method
		const mockupRefPointDistance = this.printAreaRight - this.printAreaLeft // ref point distance in pixel
		let realRefPointDistance = this.printAreaSizes[this.mockupType][this.mockupSide].maxWidth // ref point distance in cm
		// let realRefPointDistance = this.printAreaSizes[this.selectedProduct.productType][this.mockupSide].maxWidth // ref point distance in cm

		// #2 ref point method
		// const refPointLeft = this.$parent.$refs['mockupSvg'].$refs['ref-point-left']
		// const refPointRight = this.$parent.$refs['mockupSvg'].$refs['ref-point-right']
		// const mockupRefPointDistance = Math.floor(refPointRight.getBoundingClientRect().left - refPointLeft.getBoundingClientRect().left)
		// let realRefPointDistance = this.referenceDistances[this.selectedProduct.productType][this.mockupSide] // ref point distance in cm

		this.REF_CM_TO_PIXEL = mockupRefPointDistance / realRefPointDistance - 0.05 // remove a little offset to make it just a little smaller to prevent calc errors
		this.cmSize = { width: this.convertPixelToCm(this.model.image.width), height: this.convertPixelToCm(this.model.image.height) }

		console.log('DRAG: One CM is ' + this.REF_CM_TO_PIXEL + ' Pixel')

		this.cmPosition = {
			x: this.convertPixelToCm(this.model.image.x + this.model.image.translate.x - this.printAreaLeft),
			y: this.convertPixelToCm(this.model.image.y + this.model.image.translate.y - this.printAreaTop),
		}

		// updates the translate transform values to move the object to the new point
		this.updateImageTransform('translate', 'translate(' + this.printAreaLeft + 'px, ' + this.printAreaTop + 'px)')
		// updates the translate coordinates in the model for further use
		this.updateImageTranslateCoordinates(this.printAreaLeft, this.printAreaTop)

		// set init sizes
		const { finishWidth, finishHeight } = this.keepAspectRatio(this.finish.size['width'], this.finish.size['height'])
		// this.model.image.url = this.image
		this.model.image.width = finishWidth
		this.model.image.height = finishHeight
		this.model.image.x = this.convertCmToPixel(this.initX)
		this.model.image.y = this.convertCmToPixel(this.initY)

		// check if finish is now outside of the printable area, if so show warning
		this.validatePosition(finishWidth, finishHeight, this.model.image.x, this.model.image.y)

		// console.log('---')
		// console.log(this.REF_CM_TO_PIXEL)
		// console.log(this.printAreaLeft)
		// console.log(this.printAreaTop)
		// console.log(this.model.image.x)
		// console.log(this.model.image.y)
		// console.log('---')

		// selecting svg elements via d3js
		// var mainSvg = d3.select(this.$refs.svgElement)
		d3.select(this.$parent.$refs['svgContainer'])
		var controlsGroup = d3.select(this.$refs['drag-wrapper'])
		// var topLeftRotateHandleElem = controlsGroup.select('circle.rotate-tl')
		// controlsGroup.select('.rotate-tr')

		// var blueCenterCircle = d3.select('circle.blue-center-circle')
		// d3.select('.blue-center-circle')

		// when the directive is binded, stores the original
		// coordinates of the image to be used as validation
		// by the resize function
		var originalCoordinates = this.getImageUpdatedCoordinates()
		// sets the minimun values for width and height to be of 10% of the original
		// dimensions, to avoid negative values
		this.minWidth = 0.1 * originalCoordinates.width
		this.minHeight = 0.1 * originalCoordinates.height

		// transform position, used to move the object around with the drag and drop
		this.tPos = this.getImageUpdatedTranslateCoordinates()

		this.elemCenter = this.getElementCenter()
		this.updateImageRotateCoordinates(null, this.elemCenter.x, this.elemCenter.y)

		// do the bind of the drag behavior in the controls elements
		if (this.currentStep === 'motive') controlsGroup.call(this.bindControlsDragAndDrop())
	},
	methods: {
		updateInitCoords(coords) {
			// this is used to update the custom positioning from outside
			this.model.image.x = coords.x - this.model.image.width / 2
			this.model.image.y = coords.y - this.model.image.height / 2

			// set translate back to zero
			// updates the translate transform values to move the object to the new point
			this.updateImageTransform('translate', 'translate(' + this.printAreaLeft + 'px, ' + this.printAreaTop + 'px)')
			// updates the translate coordinates in the model for further use
			this.updateImageTranslateCoordinates(this.printAreaLeft, this.printAreaTop)

			// set center of image
			this.elemCenter = this.getElementCenter()
			this.updateImageRotateCoordinates(null, this.elemCenter.x, this.elemCenter.y)

			// save to store
			const pixelX = this.getImageUpdatedTranslateCoordinates().x + this.getImageUpdatedCoordinates().x - this.printAreaLeft
			const pixelY = this.getImageUpdatedTranslateCoordinates().y + this.getImageUpdatedCoordinates().y - this.printAreaTop

			const cmX = parseFloat(this.convertPixelToCm(pixelX).toFixed(1))
			const cmY = parseFloat(this.convertPixelToCm(pixelY).toFixed(1))

			this.$store.dispatch('Configurator/updateFinish', {
				key: 'coords',
				value: { x: cmX, y: cmY },
			})
		},
		validatePosition(finishWidth, finishHeight, x, y) {
			// check if finish is now outside of the printable area, if so show warning
			const translateCoordinates = this.getImageUpdatedTranslateCoordinates()

			const correctionOffset = 0.5 // otherwise the error is shown with max width/height

			const leftBorder = x + translateCoordinates.x + correctionOffset
			const rightBorder = x + translateCoordinates.x + finishWidth - correctionOffset
			const topBorder = y + translateCoordinates.y + correctionOffset
			const bottomBorder = y + translateCoordinates.y + finishHeight - correctionOffset

			if (
				leftBorder > this.printAreaLeft &&
				rightBorder < this.printAreaRight &&
				topBorder > this.printAreaTop &&
				bottomBorder < this.printAreaBottom
			) {
				let overlapping = false
				this.blockedAreas.forEach((blockedArea) => {
					overlapping = !(
						rightBorder < blockedArea.left ||
						leftBorder > blockedArea.right ||
						bottomBorder < blockedArea.top ||
						topBorder > blockedArea.bottom
					)
				})

				this.sizeWarning = overlapping
				return overlapping

				// this.sizeWarning = false
				// return true
			} else {
				this.sizeWarning = true
				return false
			}
		},
		keepAspectRatio(newWidth, newHeight) {
			const file = this.$getFileById(this.finish.fileName)

			const imageHeight = file.height
			const imageWidth = file.width

			let finishWidth = 0
			let finishHeight = 0

			if (this.finish.sizeMeasure === 'height') {
				const adjustedWidth = (this.convertCmToPixel(newHeight) * imageWidth) / imageHeight
				finishWidth = adjustedWidth
				finishHeight = this.convertCmToPixel(newHeight)
			} else if (this.finish.sizeMeasure === 'width') {
				const adjustedHeight = (this.convertCmToPixel(newWidth) * imageHeight) / imageWidth
				finishWidth = this.convertCmToPixel(newWidth)
				finishHeight = adjustedHeight
			}

			return { finishHeight, finishWidth }
		},
		convertCmToPixel(cmValue) {
			return cmValue * this.REF_CM_TO_PIXEL
		},
		convertPixelToCm(pixelValue) {
			return pixelValue / this.REF_CM_TO_PIXEL
		},
		bindControlsDragAndDrop() {
			// auxiliar variables
			// var target, targetClass, rotateHandleStartPos
			// binding the behavior callback functions
			// var drag = d3.behavior.drag().on('dragstart', dragStart).on('drag', dragMove).on('dragend', dragEnd)
			var drag = d3.drag().on('start', this.dragStart).on('drag', this.dragMove).on('end', this.dragEnd)

			return drag
		},
		/**
		 * For the drag action starts
		 * */
		dragStart(event) {
			// gets the current target element where the drag event started
			this.target = d3.select(event.sourceEvent.target)
			// and saves the target element class in a aux variable
			this.targetClass = this.target.attr('class')

			// when the user is rotating the element, stores the initial angle
			// information to be used in the rotate function
			// if (this.targetClass.indexOf('rotate') > -1) {
			// 	// gets the updated rotate coordinates
			// 	var updatedRotateCoordinates = this.getImageUpdatedRotateCoordinates()

			// 	// updates the rotate handle start posistion object with
			// 	// basic information from the model and the handles
			// 	this.rotateHandleStartPos = {
			// 		angle: updatedRotateCoordinates.angle, // the current angle
			// 		x: parseFloat(this.target.attr('cx')), // the current cx value of the target handle
			// 		y: parseFloat(this.target.attr('cy')), // the current cy value of the target handle
			// 	}

			// 	// calc the rotated top & left corner
			// 	if (this.rotateHandleStartPos.angle > 0) {
			// 		var correctsRotateHandleStartPos = this.getHandleRotatePosition(this.rotateHandleStartPos)
			// 		this.rotateHandleStartPos.x = correctsRotateHandleStartPos.x
			// 		this.rotateHandleStartPos.y = correctsRotateHandleStartPos.y
			// 	}

			// 	// adds the initial angle in degrees
			// 	this.rotateHandleStartPos.iniAngle = this.calcAngleDeg(updatedRotateCoordinates, this.rotateHandleStartPos)
			// }
		},

		/**
		 * For while the drag is happening
		 * */
		dragMove(event) {
			// checks the target class to choose the right function
			// to be executed while dragging
			// #1 - If the user is moving the element around
			if (this.targetClass.indexOf('move') > -1) {
				this.showPositionInfo = true
				this.moveObject(event)
			}
			// #2 - If the user is resizing the element
			else if (this.targetClass.indexOf('resize') > -1) {
				this.showSizeInfo = true
				this.resizeObject(this.targetClass, event)
			}
			// #3 - If the user is rotating the element
			// else if (this.targetClass.indexOf('rotate') > -1) {
			// 	this.rotateObject(this.rotateHandleStartPos, event)
			// }

			// apply the scope changes for any function that might
			// have been called, to keep things updated in the service model
			// scope.$apply()
		},

		/**
		 * For when the drag stops (the user release the element)
		 * */
		dragEnd() {
			this.showSizeInfo = false
			this.showPositionInfo = false
			// check if the user was resizing
			if (this.targetClass.indexOf('resize') > -1) {
				// updates the center of rotation after resizing the element
				this.elemCenter = this.getElementCenter()
				this.updateImageRotateCoordinates(null, this.elemCenter.x, this.elemCenter.y)
				// scope.$apply()

				let value = { width: this.cmSize.width, height: 0 }
				if (this.finish.sizeMeasure === 'height') {
					value = { width: 0, height: this.cmSize.height }
				}
				this.$store.dispatch('Configurator/updateFinish', { key: 'size', value })
			} else if (this.targetClass.indexOf('move') > -1) {
				// console.log('PRINT-AREA: ' + this.printAreaLeft + '/' + this.printAreaTop)
				// console.log('ELEM-CENTER: ' + this.elemCenter.x + '/' + this.elemCenter.x)
				// console.log('IMAGE-COORDS: ' + this.getImageUpdatedCoordinates().x + '/' + this.getImageUpdatedCoordinates().y)
				// console.log(
				// 	'TRANSLATED-COORDS: ' +
				// 		this.getImageUpdatedTranslateCoordinates().x +
				// 		'/' +
				// 		this.getImageUpdatedTranslateCoordinates().y
				// )
				// console.log('------')

				const pixelX = this.getImageUpdatedTranslateCoordinates().x + this.getImageUpdatedCoordinates().x - this.printAreaLeft
				const pixelY = this.getImageUpdatedTranslateCoordinates().y + this.getImageUpdatedCoordinates().y - this.printAreaTop

				const cmX = parseFloat(this.convertPixelToCm(pixelX).toFixed(1))
				const cmY = parseFloat(this.convertPixelToCm(pixelY).toFixed(1))

				// console.log(cmX + ' x ' + cmY)

				this.$store.dispatch('Configurator/updateFinish', {
					key: 'coords',
					value: { x: cmX, y: cmY },
				})
			}
		},

		/**
		 * Function to move the object around
		 * */
		moveObject(event) {
			// get original coordinates
			var updatedCoordinates = this.getImageUpdatedCoordinates()
			const snapOffset = 0
			const helperOffset = 0.05
			let translatePosX = this.tPos.x
			let translatePosY = this.tPos.y

			// @TODO: snap feature

			// checks if the mouse is outside the print-area or inside the blocked-area
			// increments the x/y values with the dx/dy values from the d3.event object
			// if mouse is outside on x-axxis only allow y-axxis movement, if mouse is outside on y-axxis only allow x-axxis movement
			const mouseX = parseFloat(event.sourceEvent.clientX - this.svgLeft)
			const mouseY = parseFloat(event.sourceEvent.clientY - this.svgTop)

			let mouseInBlockedArea = false

			this.blockedAreas.forEach((ba) => {
				const overlap = !(mouseX < ba.left || mouseX > ba.right || mouseY < ba.top || mouseY > ba.bottom)
				if (overlap) mouseInBlockedArea = true
			})

			if (!mouseInBlockedArea) this.blockedAreaEnteredSide = 'none'

			if (mouseX >= this.printAreaLeft && mouseX <= this.printAreaRight) {
				translatePosX += event.dx
			}
			if (mouseY >= this.printAreaTop && mouseY <= this.printAreaBottom) {
				translatePosY += event.dy
			}
			// END checks if the mouse is outside the print-area or inside the blocked-area

			const newLeft = updatedCoordinates.x + translatePosX
			const newRight = updatedCoordinates.x + translatePosX + updatedCoordinates.width
			const newTop = updatedCoordinates.y + translatePosY
			const newBottom = updatedCoordinates.y + translatePosY + updatedCoordinates.height

			// check if finish is in print area. If it at a border of the print-area, keep it at that border.
			if (newLeft <= this.printAreaLeft + snapOffset) {
				translatePosX = this.printAreaLeft - updatedCoordinates.x + helperOffset
			}
			if (newRight >= this.printAreaRight - snapOffset) {
				translatePosX = this.printAreaRight - updatedCoordinates.x - helperOffset - updatedCoordinates.width
			}
			if (newTop <= this.printAreaTop + snapOffset) {
				translatePosY = this.printAreaTop - updatedCoordinates.y + helperOffset
			}
			if (newBottom >= this.printAreaBottom - snapOffset) {
				translatePosY = this.printAreaBottom - updatedCoordinates.y - helperOffset - updatedCoordinates.height
			}

			// Depending on the side which the mouse entered the blocked area, only move the x or y axxis. Same as above but for blocked area.
			if (mouseInBlockedArea) {
				if (this.blockedAreaEnteredSide === 'top') {
					translatePosY -= event.dy
				} else if (this.blockedAreaEnteredSide === 'bottom') {
					translatePosY -= event.dy
				} else if (this.blockedAreaEnteredSide === 'left') {
					translatePosX -= event.dx
				} else if (this.blockedAreaEnteredSide === 'right') {
					translatePosX -= event.dx
				}
			}

			// check if finish is outside blocked areas. If its at a border of the blocker-area, keept it at the border.
			this.blockedAreas.forEach((blockedArea) => {
				const overlap = !(
					newRight < blockedArea.left ||
					newLeft > blockedArea.right ||
					newBottom < blockedArea.top ||
					newTop > blockedArea.bottom
				)

				if (overlap) {
					// find on which side the finish collides with the blockedArea
					const topDist = Math.abs(newBottom - blockedArea.top)
					const bottomDist = Math.abs(newTop - blockedArea.bottom)
					const leftDist = Math.abs(newRight - blockedArea.left)
					const rightDist = Math.abs(newLeft - blockedArea.right)

					Array.prototype.min = function () {
						return Math.min.apply(Math, this)
					}
					const arr = [topDist, bottomDist, leftDist, rightDist]
					const min = arr.min()
					const minIdx = arr.indexOf(min)

					// update the coords according to the collide side
					if (minIdx === 0) {
						this.blockedAreaEnteredSide = 'top'
						translatePosY = blockedArea.top - updatedCoordinates.y - helperOffset - updatedCoordinates.height
					} else if (minIdx === 1) {
						this.blockedAreaEnteredSide = 'bottom'
						translatePosY = blockedArea.bottom - updatedCoordinates.y + helperOffset
					} else if (minIdx === 2) {
						this.blockedAreaEnteredSide = 'left'
						translatePosX = blockedArea.left - updatedCoordinates.x - helperOffset - updatedCoordinates.width
					} else if (minIdx === 3) {
						this.blockedAreaEnteredSide = 'right'
						translatePosX = blockedArea.right - updatedCoordinates.x + helperOffset
					}
				}
			})

			// turn off size warning
			this.sizeWarning = false

			this.tPos.x = translatePosX
			this.tPos.y = translatePosY

			// updates the translate transform values to move the object to the new point
			this.updateImageTransform('translate', 'translate(' + this.tPos.x + 'px, ' + this.tPos.y + 'px)')
			// updates the translate coordinates in the model for further use
			this.updateImageTranslateCoordinates(this.tPos.x, this.tPos.y)
		},

		/**
		 * Function to resize the object based in the passed direction
		 * */
		resizeObject(direction, event) {
			// gets the original image coordinates from the service model
			var updatedCoordinates = this.getImageUpdatedCoordinates()

			// auxiliar variables
			var x, y, width, height

			// resize and keep center
			width = updatedCoordinates.width + ((event.dx + event.dy) / 2) * 2
			height = updatedCoordinates.height + ((event.dx + event.dy) / 2) * 2
			const { finishWidth, finishHeight } = this.keepAspectRatio(this.convertPixelToCm(width), this.convertPixelToCm(height))

			const diffX = updatedCoordinates.width - finishWidth
			const diffY = updatedCoordinates.height - finishHeight

			x = updatedCoordinates.x + diffX / 2
			y = updatedCoordinates.y + diffY / 2

			// check if finish is now outside of the printable area, if so show warning
			this.validatePosition(finishWidth, finishHeight, x, y)

			if (finishWidth > this.minWidth && finishHeight > this.minHeight) {
				// updates the image object model with the new coordinates values
				// this.updateImageCoordinates(width, height, x, y)
				this.updateImageCoordinates(finishWidth, finishHeight, x, y)
			}
		},

		/**
		 * Function to rotate the object based in the initial rotation values
		 * present in the rotateHandleStartPos object
		 * */
		// rotateObject(rotateHandleStartPos, event) {
		// 	// gets the current udapted rotate coordinates
		// 	var updatedRotateCoordinates = this.getImageUpdatedRotateCoordinates()

		// 	// increments the mouse event starting point with the mouse movement event
		// 	rotateHandleStartPos.x += event.dx
		// 	rotateHandleStartPos.y += event.dy

		// 	// calculates the difference between the current mouse position and the center line
		// 	var angleFinal = this.calcAngleDeg(updatedRotateCoordinates, rotateHandleStartPos)
		// 	// gets the difference of the angles to get to the final angle
		// 	var angle = rotateHandleStartPos.angle + angleFinal - rotateHandleStartPos.iniAngle

		// 	// converts the values to stay inside the 360 positive
		// 	angle %= 360
		// 	if (angle < 0) {
		// 		angle += 360
		// 	}

		// 	// creates the new rotate position array
		// 	var rotatePos = [angle, updatedRotateCoordinates.cx, updatedRotateCoordinates.cy]

		// 	// updates the transform rotate string value and the rotate info in the service model
		// 	this.updateImageTransform('rotate', 'rotate(' + rotatePos + ')')
		// 	// and updates the current angle with the new one
		// 	this.updateImageRotateCoordinates(angle)
		// },

		//////////////

		/**
		 * Private functions
		 * */
		// gets the passed d3 element center coordinates
		getElementCenter() {
			var uCords = this.getImageUpdatedCoordinates()
			var result = {
				x: uCords.x + uCords.width / 2,
				y: uCords.y + uCords.height / 2,
			}
			return result
		},

		/**
		 * Function to corrects the rotate handles starting position
		 */
		getHandleRotatePosition(handleStartPos) {
			// its possible to use "cx/cy" for properties
			var originalX = handleStartPos.x ? handleStartPos.x : handleStartPos.cx
			var originalY = handleStartPos.y ? handleStartPos.y : handleStartPos.cy

			// gets the updated element center, without rotatio
			var center = this.getElementCenter()
			// calculates the rotated handle position considering the current center as
			// pivot for rotation
			var dx = originalX - center.x
			var dy = originalY - center.y
			var theta = (handleStartPos.angle * Math.PI) / 180

			return {
				x: dx * Math.cos(theta) - dy * Math.sin(theta) + center.x,
				y: dx * Math.sin(theta) + dy * Math.cos(theta) + center.y,
			}
		},

		// gets the angle in degrees between two points
		calcAngleDeg(p1, p2) {
			var p1x = p1.x ? p1.x : p1.cx
			var p1y = p1.y ? p1.y : p1.cy
			return (Math.atan2(p2.y - p1y, p2.x - p1x) * 180) / Math.PI
		},
		updateImageTranslateCoordinates(x, y) {
			this.model.image.translate.x = x || this.model.image.translate.x
			this.model.image.translate.y = y || this.model.image.translate.y

			this.cmPosition = {
				x: this.convertPixelToCm(this.model.image.x + this.model.image.translate.x - this.printAreaLeft),
				y: this.convertPixelToCm(this.model.image.y + this.model.image.translate.y - this.printAreaTop),
			}
		},

		getImageUpdatedTranslateCoordinates() {
			return this.model.image.translate
		},

		/**
		 * Function to get the current image rotate coordinate values
		 * */
		// getImageUpdatedRotateCoordinates() {
		// 	return this.model.image.rotate
		// },

		/**
		 * Function to get the current image coordinate values
		 */
		getImageUpdatedCoordinates() {
			return {
				width: this.model.image.width,
				height: this.model.image.height,
				x: this.model.image.x,
				y: this.model.image.y,
			}
		},

		/**
		 * Function to update the rotate values
		 * */
		updateImageRotateCoordinates(angle, cx, cy) {
			angle = angle || this.model.image.rotate.angle
			cx = cx || this.model.image.rotate.cx
			cy = cy || this.model.image.rotate.cy

			this.model.image.rotate.angle = angle
			this.model.image.rotate.cx = cx
			this.model.image.rotate.cy = cy
		},

		/**
		 * Function to update the transform string value
		 * for the passed transform attr
		 * */
		updateImageTransform(transform, stringValue) {
			this.model.image.transform[transform] = stringValue
		},

		/**
		 * Function to update the image position and dimension
		 * coordinate values
		 * */
		updateImageCoordinates(width, height, x, y) {
			width = width || this.model.image.width
			height = height || this.model.image.height
			x = x || this.model.image.x
			y = y || this.model.image.y

			this.model.image.width = width
			this.model.image.height = height
			this.model.image.x = x
			this.model.image.y = y

			this.cmSize = { width: this.convertPixelToCm(this.model.image.width), height: this.convertPixelToCm(this.model.image.height) }
			this.cmPosition = {
				x: this.convertPixelToCm(this.model.image.x + this.model.image.translate.x - this.printAreaLeft),
				y: this.convertPixelToCm(this.model.image.y + this.model.image.translate.y - this.printAreaTop),
			}
		},
	},
}
</script>

<style scoped lang="sass">
.drag-wrapper
	position: absolute
	zIndex: 10

	.size-rect, .position-rect, .move-rect, .resize-btn
		display: none
	&.selected-finish
		.size-rect, .position-rect, .move-rect, .resize-btn
			display: block

	.size-rect, .position-rect
		background: white
		color: white
		position: absolute
		width: 130px
		padding: 2px 0
		bottom: -38px
		font-weight: 700
		border: 1px solid black
		border-radius: 2px
		fontSize: 10px
		textAlign: center
		opacity: 0.8
		background: black
	.move-rect
		position: absolute
		left: 0px
		top: 0px
		border-radius: 0px
		border: 1px solid transparent
		cursor: move

	// .image-element
	// 	outline: 1px dashed white
	// 	outline-offset: 6px // does not work in ie

	.resize-btn
		position: absolute
		width: 20px
		height: 20px
		bottom: -10px
		right: -10px
		border: none
		cursor: nw-resize
		background: black
		&:hover
			transform: scale(1.2)
		i
			display: inline-flex
			font-size: 17px
			padding: 2px
			color: white
			transform: rotate(90deg)
			pointer-events: none
</style>