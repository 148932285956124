<template>
	<div class="ecommerce-application for-print" :class="`${printState ? 'print-style' : ''}`">
		<b-row v-if="!requestDetails">
			<b-col cols="12">
				<div class="text-center">
					<b-spinner variant="primary" label="Text Centered" />
				</div>
			</b-col>
		</b-row>
		<div v-else>
			<b-row class="no-print print-button-wrapper">
				<b-col class="d-flex justify-content-center my-2">
					<b-button variant="danger" @click="printPDF">Print PDF</b-button>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="2" class="hideOnPrint">
					<ul class="sub-navigation">
						<li class="mb-2" @click="goto('messages')">Verlauf</li>
						<li class="mb-2" @click="goto('configs')">Konfigurationen</li>
						<li
							class="ml-1 mb-2 sub-sub-nav-item"
							@click="goto('config' + configIdx)"
							v-for="(config, configIdx) of requestDetails.configurations"
							:key="configIdx"
						>
							{{ parseInt(configIdx) + 1 }} - {{ config.ref.name }}
						</li>
						<li @click="goto('customer')">Kunde</li>
						<!-- <li>
							<b-button small @click="trello('add')">Trello: addCard</b-button>
						</li>
						<li>
							<b-button small @click="trello('change')">Trello: changeCard</b-button>
						</li> -->
					</ul>
				</b-col>
				<b-col cols="7" class="fullWidthOnPrint">
					<div ref="messages">
						<MessageTimeline :request="requestDetails" />
					</div>
					<div ref="configs" class="ref-config">
						<div v-for="(conf, idx) in requestDetails.configurations" :key="idx" :ref="'config' + idx">
							<Config
								:index="idx"
								:confCount="requestDetails.configurations.length"
								:configuration="conf"
								:configurationIdx="idx"
								:request="requestDetails"
							/>
						</div>
					</div>
					<div ref="customer">
						<CustomerInfo :request="requestDetails" />
					</div>
					<div ref="print-messages" class="print-messages">
						<PrintMessageTimeline :request="requestDetails" filter="message|note" />
					</div>
				</b-col>
				<b-col cols="3">
					<StatusBox class="status-box" :request="requestDetails" />
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import { BRow, BCol, BSpinner, BButton, BCard } from 'bootstrap-vue'

import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

import CustomerInfo from './partials/CustomerInfo'
import StatusBox from './partials/StatusBox'
import MessageTimeline from './partials/MessageTimeline'
import PrintMessageTimeline from './partials/PrintMessageTimeline'
import Config from './partials/Config'

export default {
	name: 'RequestDetails',
	components: {
		BRow,
		BCol,
		BSpinner,
		CustomerInfo,
		StatusBox,
		MessageTimeline,
		PrintMessageTimeline,
		BButton,
		BCard,
		Config,
	},
	data() {
		return {
			// requestDetails: null,
		}
	},
	computed: {
		...mapGetters({
			getRequest: 'Requests/getRequestById',
			// getAllRequests: 'Requests/getAllRequests',
			requestDetails: 'Requests/getCurrentRequest',
		}),
		printState() {
			return this.$route.query.print
		},
	},
	mounted() {
		this.$store.dispatch('Requests/setCurrentRequest', null)
		// this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'boxed')

		this.$store.dispatch('Requests/fetchRequests').then(() => {
			const request = this.getRequest(this.$route.params.requestId)
			// this.requestDetails = this.getRequest(this.$route.params.requestId)

			// fetch files for current request
			this.$store.dispatch('Requests/fetchRequestFiles', this.$route.params.requestId).then(() => {
				// set current request to store
				this.$store.dispatch('Requests/setCurrentRequest', request)

				// update pageTitle from meta tags in route
				// this.$route.meta.pageTitle = 'Anfrage ' + this.requestDetails.publicId

				// Add a temporary query parameter.
				const orgQuery = this.$route.query
				this.$router.replace({ query: { temp: Date.now() } })
				// Remove the temporary query parameter.
				this.$router.replace({ query: { temp: undefined, ...orgQuery } })

				// join websocket room
				this.$socket.client.emit('join_request', `admin-${this.$route.params.requestId}`)
			})
		})
	},
	watch: {
		// getAllRequests() {
		// 	this.requestDetails = this.getRequest(this.$route.params.requestId)
		// },
	},
	destroyed() {
		this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', this.contentWidth)
	},
	methods: {
		formatDate(date) {
			return dayjs(date).format('DD.MM.YYYY')
		},
		formatTime(date) {
			return dayjs(date).format('HH:mm')
		},
		goto(refName) {
			const element = this.$refs[refName]
			let top = element.offsetTop

			if (!top && element[0]) {
				top = element[0].offsetTop
			}

			window.scrollTo({
				top,
				left: 0,
				behavior: 'smooth',
			})
		},
		trello(type) {
			if (type === 'add') this.$store.dispatch('Trello/addCard', { requestId: this.$route.params.requestId, category: 'new' })
			if (type === 'change')
				this.$store.dispatch('Trello/changeCard', { requestId: this.$route.params.requestId, category: 'offer-send' })
		},
		printPDF() {
			window.print()
		},
	},
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="sass" scoped>

.status-box
	position: fixed
	margin-right: 28px
	height: calc(100vh - 130px)
	overflow-y: auto
	overflow-x: hidden
.sub-navigation
	margin: 0
	padding: 0
	list-style-type: none
	position: fixed
	li
		cursor: pointer
		opacity: 0.8
		transition: all 200ms
		font-size: 14px
		&:hover
			opacity: 1

	.sub-sub-nav-item
		font-size: 11px
		max-width: 130px

.print-button-wrapper
	display: none

.print-style
	background: blue
	.print-button-wrapper
		display: block

.print-messages
	display: none

@media print
	.no-print, .no-print *
		display: none !important
	.print-messages
		display: block
</style>
