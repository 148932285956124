<template>
	<div>
		<b-button class="hange-file-btn mt-0 px-0" variant="link" size="sm" @click="modal = !modal">Datei ändern</b-button>

		<b-modal
			id="change-file-modal"
			title="Datei ändern"
			v-model="modal"
			ok-title="Speichern"
			cancel-title="Abbrechen"
			:busy="saving"
			@ok="(ev) => save(ev)"
			:ok-disabled="!this.selectedFile"
			size="lg"
			scrollable
		>
			<b-row class="inner-content">
				<b-col cols="12">
					<div class="py-1 px-1 d-flex align-items-start flex-wrap">
						<b-card class="my-1 mx-1 shadow-none image-wrapper">
							<FileUpload :requestId="requestId" />
						</b-card>
						<b-card
							class="my-1 mx-1 shadow-none image-wrapper"
							:class="[selectedFile && selectedFile._id === file._id && 'active']"
							v-for="(file, idx) in filesArray"
							:key="idx"
							@click="selectFile(file)"
						>
							<div class="image" :style="{ backgroundImage: `url(${file.url})` }"></div>

							<div class="label mt-1">{{ file.label }}</div>
							<div>Datum: {{ formatDate(file.createdAt) }}</div>
							<div>Größe: {{ file.width }} x {{ file.height }} Pixel</div>
							<div v-if="file.fileType">Dateityp: {{ file.fileType }}</div>
							<div class="d-flex justify-content-end mt-1">
								<a :href="file.url" target="_blank" @click="(ev) => ev.stopPropagation()">Original</a>
							</div>
						</b-card>
					</div>
				</b-col>
			</b-row>
			<div></div>
		</b-modal>
	</div>
</template>

<script>
import FileUpload from './partials/FileUpload.vue'
import { BButton, BModal, BSpinner, BRow, BCol, BCard } from 'bootstrap-vue'
import { mapFields } from 'vuex-map-fields'
import dayjs from 'dayjs'

export default {
	name: 'ChangeFileModal',
	components: { BButton, BModal, BSpinner, BRow, BCol, BCard, FileUpload },
	props: {
		variantFinish: { type: Object, required: true },
		variantIdx: { type: String, required: true },
		finish: { type: Object, required: true },
		finishIdx: { type: String, required: true },
		files: { type: Array, required: true },
		configIdx: { type: String, required: true },
		requestId: { type: String, required: true },
	},
	computed: {
		...mapFields('Requests', {
			filesArray: 'files',
		}),
	},
	data() {
		return {
			modal: false,
			saving: false,
			selectedFile: null,
		}
	},
	watch: {
		modal() {
			this.selectedFile = null
		},
	},
	methods: {
		formatDate(date) {
			return dayjs(date).format('DD.MM.YYYY')
		},
		selectFile(file) {
			this.selectedFile = file
		},
		save(ev) {
			ev.preventDefault()

			const data = {}
			const keyString = `configurations.${this.configIdx}.finishes.${this.finishIdx}.fileName`
			data[keyString] = this.selectedFile._id
			data.fileChanged = true
			data.finishIdx = this.finishIdx

			this.saving = true
			this.$store.dispatch('Requests/update', { id: this.requestId, data }).finally(() => {
				this.saving = false
				this.modal = false
			})
		},
	},
}
</script>

<style lang="sass" scoped>
.inner-content
	background: #f8f8f8
	font-size: 12px

	.image-wrapper
		cursor: pointer
		width: 200px
		transition: all 200ms
		border: 4px solid white

		&:hover, &.active
			border-color: #7367f0

		.image
			width: 100%
			height: 80px
			background-size: contain
			background-position: center
			background-repeat: no-repeat
			background-color: rgba(0,0,0,0.05)

		.label
			font-weight: 700
</style>