<template>
	<div>
		<div class="text-center wrapper">
			<SVGFront
				v-if="currentSide === 'front'"
				mockupSide="front"
				:mainColor="variant.extractedHexColor"
				:secColor="variant.extractedHexColor"
				currentStep="admin"
				:allFinishes="conf.finishes"
				:files="files"
				:mockupType="conf && conf.ref && conf.ref.productType"
			/>
			<SVGBack
				v-if="currentSide === 'back'"
				mockupSide="back"
				:mainColor="variant.extractedHexColor"
				:secColor="variant.extractedHexColor"
				currentStep="admin"
				:allFinishes="conf.finishes"
				:files="files"
				:mockupType="conf && conf.ref && conf.ref.productType"
			/>
			<SVGLeft
				v-if="currentSide === 'left'"
				mockupSide="left"
				:mainColor="variant.extractedHexColor"
				:secColor="variant.extractedHexColor"
				currentStep="admin"
				:allFinishes="conf.finishes"
				:files="files"
				:mockupType="conf && conf.ref && conf.ref.productType"
			/>
			<SVGRight
				v-if="currentSide === 'right'"
				mockupSide="right"
				:mainColor="variant.extractedHexColor"
				:secColor="variant.extractedHexColor"
				currentStep="admin"
				:allFinishes="conf.finishes"
				:files="files"
				:mockupType="conf && conf.ref && conf.ref.productType"
			/>
			<!-- <div>{{ variant.color }}</div> -->
			<div
				:class="[
					'my-1',
					'side-switch-wrapper',
					'd-flex',
					'align-items-center',
					'justify-content-center',
					'flex-row',
					fixedButtons && 'fixed',
				]"
			>
				<div v-if="hasSide('front')" @click="currentSide = 'front'" :class="[{ active: currentSide === 'front' }, 'side-switch']">
					Vorne
				</div>
				<div v-if="hasSide('back')" @click="currentSide = 'back'" :class="[{ active: currentSide === 'back' }, 'side-switch']">
					Hinten
				</div>
				<div
					v-if="hasSide('left') || hasPosition('shoulder-sleeve-left-custom')"
					@click="currentSide = 'left'"
					:class="[{ active: currentSide === 'left' }, 'side-switch']"
				>
					Ärmel links
				</div>
				<div
					v-if="hasSide('right') || hasPosition('shoulder-sleeve-right-custom')"
					@click="currentSide = 'right'"
					:class="[{ active: currentSide === 'right' }, 'side-switch']"
				>
					Ärmel rechts
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import SVGFront from './mockups/SVGFront'
import SVGBack from './mockups/SVGBack'
import SVGLeft from './mockups/SVGLeft'
import SVGRight from './mockups/SVGRight'

export default {
	name: 'MockupDisplay',
	components: { BButton, SVGFront, SVGBack, SVGLeft, SVGRight },
	directives: {
		Ripple,
	},
	props: {
		variant: { type: Object, required: true },
		conf: { type: Object, required: true },
		files: { type: Array, required: true },
		fixedButtons: { type: Boolean, default: false },
		customCurrentSide: { type: String, required: false },
	},
	computed: {
		currentSide: {
			get() {
				if (!this.customCurrentSide && !this.currentSideLocal) this.currentSideLocal = this.findSide()
				else if (!this.currentSideLocal) this.currentSideLocal = this.customCurrentSide
				return this.currentSideLocal
			},
			set(val) {
				this.currentSideLocal = val
			},
		},
	},
	data() {
		return {
			currentSideLocal: null,
		}
	},
	methods: {
		findSide() {
			const position = this.conf.finishes[0].position
			if (position === 'shoulder-sleeve-left-custom') return 'left'
			else if (position === 'shoulder-sleeve-right-custom') return 'right'
			else return this.conf.finishes[0].side
		},
		hasSide(side) {
			let exists = false
			for (let key of Object.keys(this.conf.finishes)) {
				if (this.conf.finishes[key].side === side) exists = true
			}
			return exists
		},
		hasPosition(position) {
			let exists = false
			for (let key of Object.keys(this.conf.finishes)) {
				if (this.conf.finishes[key].position === position) exists = true
			}
			return exists
		},
	},
}
</script>

<style lang="sass" scoped>
.side-switch-wrapper
	&.fixed
		position: fixed
		z-index: 100
		bottom: 40px
	.side-switch
		font-size: 12px
		color: #5E5873
		font-weight: 700
		opacity: 0.2
		padding: 0 5px
		cursor: pointer
		&.active
			opacity: 1
</style>
