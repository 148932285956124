<template>
	<div id="left-mockup" ref="svgContainer" :style="mockupWidth && { width: mockupWidth, height: mockupHeight }">
		<div
			v-for="(finish, idx) in filteredFinishes"
			:key="idx + '_filtered'"
			class="finish-wrapper"
			:style="isMounted && getFinishPosition(finish, 'finish_' + idx)"
			:ref="'finish_' + idx"
		>
			<img :src="$getFileUrlById(finish.fileName)" />
		</div>

		<DragFinish
			v-for="(finish, idx) in customFinishes"
			:key="idx + '_custom'"
			:finish="finish"
			:image="$getFileUrlById(finish.fileName)"
			:width="parseFloat(finish.size.width)"
			:height="finish.size.height"
			:initX="finish.coords ? finish.coords.x : 0"
			:initY="finish.coords ? finish.coords.y : 0"
			:mockupSide="mockupSide"
			:mockupType="mockupType"
			:style="cssVars"
			:currentStep="currentStep"
			:allFinishes="allFinishes"
			:files="files"
		/>

		<ShirtLeft
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'shirt' || mockupType === 'shirt-pocket' || mockupType === 'shirt-round-neck'"
			:style="cssVars"
		/>

		<HoodieLeft
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'hoodie' || mockupType === 'hoodie-bag' || mockupType === 'hoodie-zipper'"
			:style="cssVars"
		/>

		<LongsleeveLeft
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'longsleeve' || mockupType === 'longsleeve-pocket'"
			:style="cssVars"
		/>

		<CrewneckLeft
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'crewneck'"
			:style="cssVars"
		/>

		<PoloLeft
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'polo'"
			:style="cssVars"
		/>

		<CapLeft
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap' || mockupType === 'cap-strap'"
			:style="cssVars"
		/>

		<CapCurvedSnapbackLeft
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-curved-snapback'"
			:style="cssVars"
		/>

		<CapDadLeft
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-dad' || mockupType === 'cap-dad-belt-buckle'"
			:style="cssVars"
		/>

		<CapFlatSnapbackLeft
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-flat-snapback'"
			:style="cssVars"
		/>

		<CapUnstructuredSnapbackLeft
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-unstructured-snapback'"
			:style="cssVars"
		/>
	</div>
</template>

<script>
import svgComponentData from './svgComponentData'
import ShirtLeft from './textiles/shirt/ShirtLeft'
import HoodieLeft from './textiles/hoodie/HoodieLeft'
import LongsleeveLeft from './textiles/longsleeve/LongsleeveLeft'
import CrewneckLeft from './textiles/crewneck/CrewneckLeft'
import PoloLeft from './textiles/polo/PoloLeft'
import CapLeft from './textiles/cap/CapLeft'
import CapCurvedSnapbackLeft from './textiles/cap-curved-snapback/CapCurvedSnapbackLeft'
import CapDadLeft from './textiles/cap-dad/CapDadLeft'
import CapFlatSnapbackLeft from './textiles/cap-flat-snapback/CapFlatSnapbackLeft'
import CapUnstructuredSnapbackLeft from './textiles/cap-unstructured-snapback/CapUnstructuredSnapbackLeft'

import DragFinish from './DragFinish'

const componentData = { ...svgComponentData }

componentData.name = 'SVGLeft'
componentData.components = {
	ShirtLeft,
	HoodieLeft,
	LongsleeveLeft,
	CrewneckLeft,
	PoloLeft,
	CapLeft,
	CapCurvedSnapbackLeft,
	CapDadLeft,
	CapFlatSnapbackLeft,
	CapUnstructuredSnapbackLeft,
	DragFinish,
}

export default componentData
</script>

<style scoped lang="sass">
#left-mockup
	position: relative

.finish-wrapper
	position: absolute
	z-index: 10

	img
		width: 100%
		height: 100%

	&[data-position=""]
		display: none

	// front
	&[data-position="custom-front"] // Druck/Stick -> Vorne -> Custom
		top: 88px
		right: 190px
		cursor: move
</style>

<style lang="sass">
#left-mockup
	#PRINT-AREA
		stroke: var(--color) !important
	#POSITIONEN
		#center, #left, #right
			stroke: var(--color) !important
			opacity: var(--dotOpacity) !important
	#drag-finish
		.image-element
			outline-color: var(--color) !important
		.resize-btn
			background: var(--color) !important
			i
				color: var(--invertColor) !important
</style>