<template>
	<div :class="['wrapper', small && 'small']">
		<div class="label">{{ label }}</div>
		<div class="d-flex justify-content-between align-items-center flex-row cost-row">
			<div class="mr-1 d-flex justify-content-start align-items-center flex-row" style="width: 140px" v-if="!editable && showAmount">
				<span class="currency small mr-1">Anzahl:</span> {{ amount }}
			</div>

			<div class="d-flex align-items-center">
				<div
					:class="[
						'mr-1',
						'd-flex',
						'justify-content-center',
						'align-items-center',
						'flex-row',
						'amount-input',
						localValue && 'edited',
					]"
					style="width: 140px"
					v-if="editable"
				>
					<b-button
						variant="primary"
						class="change-button shadow-none"
						size="sm"
						@click="changeAmount(-1)"
						:disabled="formattedAmount === 0"
					>
						<feather-icon icon="MinusIcon" class="icon" />
					</b-button>
					<b-form-input
						min="0"
						type="number"
						class="text-input"
						v-model="formattedAmount"
						size="lg"
						:disabled="false"
						@keydown="filterKey"
						@blur="update"
					></b-form-input>
					<b-button
						variant="primary"
						class="change-button shadow-none"
						size="sm"
						@click="changeAmount(1)"
						:disabled="max !== -1 && formattedAmount >= max"
					>
						<feather-icon icon="PlusIcon" class="icon" />
					</b-button>
				</div>
				<span class="currency small" v-if="editable">Anzahl</span>
			</div>

			<div class="d-flex align-items-center input-wrapper" v-if="!hideInput">
				<div class="ml-3 d-flex justify-content-center align-items-center flex-row" style="width: 70px">
					<b-form-input
						min="0"
						type="number"
						class="text-input"
						v-model="formattedValue"
						size="lg"
						:disabled="true"
					></b-form-input>
				</div>
				<span class="currency">€</span>
			</div>
		</div>
	</div>
</template>

<script>
import { BButton, BModal, BRow, BCol, BFormInput } from 'bootstrap-vue'

export default {
	name: 'AmountInput',
	components: { BButton, BModal, BRow, BCol, BFormInput },
	props: {
		label: { type: String, required: true },
		value: { type: Number, required: true },
		amount: { type: Number, required: false },
		editable: { type: Boolean, default: false },
		showAmount: { type: Boolean, default: false },
		hideInput: { type: Boolean, default: false },
		valueKey: { type: String, required: false },
		updateFn: { type: Function, required: false },
		updateKey: { type: String, required: false },
		updated: { type: Object, required: false },
		small: { type: Boolean, default: false },
		max: { type: Number, default: -1 },
	},
	computed: {
		formattedAmount: {
			get() {
				return this.localValue === null ? this.amount : this.localValue
			},
			set(amount) {
				this.localValue = amount
				// if (!isNaN(amount) && this.updateFn) this.updateFn(this.updateKey, parseFloat(amount))
			},
		},
		formattedValue() {
			return parseFloat((Math.round(this.value * 100) / 100).toFixed(2))
		},
	},
	data() {
		return {
			localValue: null,
		}
	},
	methods: {
		update() {
			const amount = this.localValue
			const that = this
			if (amount !== null && !isNaN(amount) && this.updateFn) {
				if ((this.max > -1 && amount <= this.max) || this.max === -1) {
					console.log('updateValue: ', parseFloat(amount))
					this.updateFn(this.updateKey, parseFloat(amount), () => {
						that.localValue = null
					})
				}
			}
		},
		changeAmount(value) {
			if (this.max > -1) {
				if (this.formattedAmount + value <= this.max) {
					this.formattedAmount = this.formattedAmount + value
					this.update()
				}
			} else {
				this.formattedAmount = this.formattedAmount + value
				this.update()
			}
		},
		filterKey(e) {
			// let previousValue = e.target.value
			let key = e.keyCode

			console.log('Keycode: ', key)

			if (
				key === 8 || // backspace
				(key >= 37 && key <= 40) || // cursor keys
				(key >= 48 && key <= 57) || // 0..9
				// key === 188 || // comma
				key === 91 || // cmd (mac, for copy/paste)
				key === 17 || // control (windows, for copy/paste)
				key === 67 || // c (for copy)
				key === 86 // v (for paste)
			) {
				// all good
			} else if (key === 27) {
				// key: esc, reset value
				e.preventDefault()
				e.stopPropagation()
				this.localValue = null
			} else if (key === 13) {
				// key: enter, update
				this.$refs.input.blur()
			} else {
				// prevent input
				e.preventDefault()
			}

			return
		},
	},
}
</script>

<style lang="sass" scoped>
.wrapper
	background: rgba(0,0,0,0.02)
	border-radius: 0.358rem
	padding: 8px
	margin-bottom: 4px

	&.small
		display: flex
.label
	font-size: 12px
	flex-grow: 1
	font-weight: 500
	// margin-top: 8px
	margin-bottom: 8px
.input-wrapper:only-child
	margin-left: auto
.text-input
	background: #F1F1F1
	border-color: #F1F1F1
	font-size: 14px
	color: #5E5873
	font-weight: 500
	text-align: center
	padding: 0
	height: 28px !important

.amount-input
	&.edited
		.text-input
			background-clip: content-box
			background-color: rgba(255, 204, 0, 0.6)
			border-color: rgba(255, 204, 0, 0.6)
			// background-color: rgba(234, 84, 85, 0.2)
			// border-color: rgba(234, 84, 85, 0.2)

.change-button
	z-index: 10
	position: relative
	padding: 6px
	margin-right: -16px
	border-radius: 4px 0 0 4px
	height: 28px !important
	width: 28px !important
	&:last-child
		border-radius: 0 4px 4px 0
		margin-right: 0
		margin-left: -16px
	&.disabled
		opacity: 1
		background-color: rgb(165, 153, 290) !important
		border-color: rgb(165, 153, 290) !important
.currency
	opacity: 0.5
	font-size: 16px
	margin-left: 4px
	&.small
		margin-left: 0px
		font-size: 12px
</style>