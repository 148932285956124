<template>
	<b-card class="hideOnPrint shadow-none" ref="messages">
		<div class="mb-2 d-flex justify-content-between">
			<div><feather-icon icon="ListIcon" class="mr-50" /> Verlauf</div>
			<div>
				<b-button
					size="sm"
					:variant="timelineFilter === null ? 'secondary' : 'outline-secondary'"
					class="mr-1"
					@click="timelineFilter = null"
					>Alle</b-button
				>
				<b-button
					size="sm"
					:variant="timelineFilter === 'message' ? 'secondary' : 'outline-secondary'"
					class="mr-1"
					@click="timelineFilter = 'message'"
					>Nachrichten</b-button
				>
				<b-button
					size="sm"
					:variant="timelineFilter === 'note' ? 'secondary' : 'outline-secondary'"
					class="mr-1"
					@click="timelineFilter = 'note'"
					>Notizen</b-button
				>
				<b-button
					size="sm"
					:variant="timelineFilter === '(event|offer|event+file)' ? 'secondary' : 'outline-secondary'"
					@click="timelineFilter = '(event|offer|event+file)'"
					>Ereignisse</b-button
				>
			</div>
		</div>

		<div id="messagescreen" ref="messagescreen">
			<div class="inner" ref="inner">
				<div
					:class="[
						'message-row',
						(entry.type === 'customer-message' || entry.userName === 'customer' || entry.customer) && 'customer',
					]"
					v-for="(entry, entryIdx) of sortedTimeline"
					:key="entryIdx"
				>
					<div class="message">
						<div class="meta">
							<span v-if="entry.type === 'customer-message'">
								Nachricht von {{ entry.customer.firstname }} {{ entry.customer.lastname }} (Kunde)
							</span>
							<span v-if="entry.type === 'message'">
								Nachricht von {{ entry.user ? `${entry.user.firstname} ${entry.user.lastname}` : 'werc' }}
							</span>
							<span v-if="entry.type === 'internal-note'">
								Notiz von {{ entry.user ? `${entry.user.firstname} ${entry.user.lastname}` : 'werc' }}
							</span>
							<span v-if="entry.type === 'event' && entry.status"> Status Änderung </span>
							<span v-if="(entry.type === 'event+file' || entry.type === 'offer') && entry.status">
								Status Änderung und Datei-Download
							</span>
							<span>{{ formatDate(entry.createdAt) }} - {{ formatTime(entry.createdAt) }}Uhr</span>
						</div>
						<div v-if="entry.type === 'message' || entry.type === 'internal-note'" class="delete-button">
							<b-button
								variant="flat-danger"
								class="btn-icon rounded-circle"
								size="sm"
								@click="deleteEntry(entry)"
								:disabled="saving"
							>
								<feather-icon icon="Trash2Icon" />
							</b-button>
						</div>
						<div
							class="message-content"
							:class="[
								entry.type === 'internal-note' && 'internal-note',
								entry.type === 'offer' && !entry.active && 'inactive-offer',
							]"
						>
							<a :href="entry.file.url" target="_blank" v-if="entry.file">
								<div
									class="file d-flex justify-content-between align-items-center flex-column"
									v-if="entry.type.match('file') || entry.type === 'offer'"
								>
									<div
										class="img-wrapper"
										:style="{ backgroundImage: `url(${entry.file.url})` }"
										v-if="entry.type === 'file-added'"
									></div>
									<feather-icon icon="SearchIcon" size="24" style="margin-top: 6px" v-else />
									<span class="text-center">{{ entry.file.name }}</span>
									<!-- <span class="text-center mt-1">Angebot</span> -->
								</div>
							</a>
							<!-- prettier-ignore -->
							<div class="message-text">{{ entry.text }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="d-flex align-items-start mt-1">
			<!-- <b-form-input v-model="messageText" placeholder="Nachricht an Kunde.."></b-form-input> -->
			<b-form-textarea
				id="message-input"
				v-model="messageText"
				placeholder="Nachricht an Kunde.."
				rows="3"
				max-rows="6"
			></b-form-textarea>
			<b-button class="ml-1 send-message-btn" size="sm" variant="secondary" @click="sendMessage" :disabled="saving">Senden</b-button>
		</div>
		<div class="d-flex align-items-start mt-1">
			<!-- <b-form-input v-model="noteText" placeholder="Interne Notiz.."></b-form-input> -->
			<b-form-textarea id="note-input" v-model="noteText" placeholder="Interne Notiz.." rows="3" max-rows="6"></b-form-textarea>
			<b-button class="ml-1 send-message-btn" size="sm" variant="secondary" @click="sendNote" :disabled="saving">Speichern</b-button>
		</div>
	</b-card>
</template>

<script>
import { BFormInput, BFormTextarea, BButton, BRow, BCol, BCard } from 'bootstrap-vue'
import _ from 'lodash'
import dayjs from 'dayjs'

export default {
	name: 'MessageTimeline',
	components: { BFormInput, BFormTextarea, BButton, BRow, BCol, BCard },
	props: {
		request: { type: Object, required: true },
		filter: { type: String, default: null, required: false },
	},
	data() {
		return {
			scrolled: false,
			lastScroll: 0,
			messageText: null,
			noteText: null,
			saving: false,
			timelineFilter: null,
		}
	},
	computed: {
		sortedTimeline() {
			const adminTimeline = this.request.timeline.filter((e) => !e.scope || e.scope === 'admin')
			const timeline =
				this.timelineFilter === null
					? adminTimeline
					: adminTimeline.filter((e) => e.type.match(new RegExp(this.timelineFilter, 'gi')))
			return _.orderBy(timeline, ['createdAt'], ['asc'])
		},
	},
	mounted() {
		this.$refs['messagescreen'].addEventListener('scroll', this.autoScroll)
		this.updateScroll(true)
		if (this.filter) {
			this.timelineFilter = this.filter
		}
	},
	beforeDestroy() {
		this.$refs['messagescreen'].removeEventListener('scroll', this.autoScroll)
	},
	watch: {
		sortedTimeline() {
			setTimeout(this.updateScroll, 200) // @TODO: find a better solution
		},
		request() {
			setTimeout(this.updateScroll, 200) // @TODO: find a better solution
		},
		'request.timeline'() {
			setTimeout(this.updateScroll, 200) // @TODO: find a better solution
		},
		timelineFilter() {
			this.scrolled = false
			setTimeout(() => this.updateScroll(true), 1)
		},
	},
	methods: {
		sendMessage() {
			if (this.messageText && this.messageText.trim().length > 0) {
				this.saving = true
				this.$store.dispatch('Requests/addMessage', { requestId: this.request._id, text: this.messageText.trim() }).then(() => {
					this.saving = false
				})
				setTimeout(this.updateScroll, 200)
				this.messageText = null
			}
		},
		sendNote() {
			if (this.noteText && this.noteText.trim().length > 0) {
				this.saving = true
				this.$store.dispatch('Requests/addNote', { requestId: this.request._id, text: this.noteText.trim() }).then(() => {
					this.saving = false
				})
				setTimeout(this.updateScroll, 200)
				this.noteText = null
			}
		},
		deleteEntry(entry) {
			this.saving = true
			this.$store.dispatch('Requests/deleteEntry', { requestId: this.request._id, data: entry }).then(() => {
				this.saving = false
			})
		},
		formatDate(date) {
			return dayjs(date).format('DD.MM.YYYY')
		},
		formatTime(date) {
			return dayjs(date).format('HH:mm')
		},
		updateScroll(instantScroll = false) {
			if (!this.scrolled) {
				const element = this.$refs['messagescreen']
				const inner = this.$refs['inner']
				if (instantScroll) element.scrollTop = inner.scrollHeight
				else
					element.scrollTo({
						top: inner.scrollHeight,
						left: 0,
						behavior: 'smooth',
					})
			}
		},
		autoScroll() {
			const nextScroll = this.$refs['messagescreen'].scrollTop

			if (nextScroll <= this.lastScroll) {
				this.scrolled = true
			}
			this.lastScroll = nextScroll

			// console.log(nextScroll, this.$refs['inner'].offsetHeight)
			if (nextScroll + 100 == this.$refs['inner'].offsetHeight) {
				this.scrolled = false
			}
		},
	},
}
</script>

<style lang="sass" scoped>
#messagescreen
	height: 400px
	overflow-y: auto
	.inner
		padding-right: 8px

.message-row
	display: flex
	justify-content: flex-end
	margin: 0 0 40px
	&:first-child
		margin-top: 20px
	&.customer
		justify-content: flex-start

.send-message-btn
	background-color: lightgrey !important
	border-color: lightgrey !important
	color: grey !important
	flex-grow: 0
	flex-shrink: 0

.message
	width: 60%
	font-size: 11px
	display: flex
	flex-direction: column
	position: relative
	.meta
		color: #6E6B7B
		opacity: 0.5
		display: flex
		justify-content: space-between
		margin-bottom: 4px
		font-size: 10px
	.delete-button
		position: absolute
		top: 22px
		right: 4px
		z-index: 10
	.message-content
		background-color: #F5F5F5
		border-radius: 4px 4px 4px 0
		padding: 12px
		position: relative
		display: flex
		justify-content: flex-start
		flex-shrink: 0
		flex-direction: row

		&:after
			content: " "
			position: absolute
			z-index: 10
			bottom: -14px
			left: 0
			border-top: 7px solid #F5F5F5
			border-left: 7px solid #F5F5F5
			border-bottom: 7px solid transparent
			border-right: 7px solid transparent
			height: 0px
			width: 0px

		&.internal-note
			background-color: rgba(255, 204, 0, 0.6)
			&:after
				border-top: 7px solid rgba(255, 204, 0, 0.6)
				border-left: 7px solid rgba(255, 204, 0, 0.6)

		&.inactive-offer
			opacity: 0.3

		.file
			min-width: 60px
			width: 60px
			height: 80px
			border-radius: 4px
			border: 2px solid #D1D1D1
			background-color: #FFFFFF
			margin-right: 10px
			cursor: pointer
			padding: 0 2px
			span
				font-size: 8px

			.img-wrapper
				width: 100%
				height: 60px
				background-size: contain
				background-position: center
				background-repeat: no-repeat

		.message-text
			white-space: pre-wrap
			padding-right: 40px
</style>
