var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['wrapper', _vm.small && 'small']},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"d-flex justify-content-between align-items-center flex-row cost-row"},[(!_vm.editable && _vm.showAmount)?_c('div',{staticClass:"mr-1 d-flex justify-content-start align-items-center flex-row",staticStyle:{"width":"140px"}},[_c('span',{staticClass:"currency small mr-1"},[_vm._v("Anzahl:")]),_vm._v(" "+_vm._s(_vm.amount)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center"},[(_vm.editable)?_c('div',{class:[
					'mr-1',
					'd-flex',
					'justify-content-center',
					'align-items-center',
					'flex-row',
					'amount-input',
					_vm.localValue && 'edited' ],staticStyle:{"width":"140px"}},[_c('b-button',{staticClass:"change-button shadow-none",attrs:{"variant":"primary","size":"sm","disabled":_vm.formattedAmount === 0},on:{"click":function($event){return _vm.changeAmount(-1)}}},[_c('feather-icon',{staticClass:"icon",attrs:{"icon":"MinusIcon"}})],1),_c('b-form-input',{staticClass:"text-input",attrs:{"min":"0","type":"number","size":"lg","disabled":false},on:{"keydown":_vm.filterKey,"blur":_vm.update},model:{value:(_vm.formattedAmount),callback:function ($$v) {_vm.formattedAmount=$$v},expression:"formattedAmount"}}),_c('b-button',{staticClass:"change-button shadow-none",attrs:{"variant":"primary","size":"sm","disabled":_vm.max !== -1 && _vm.formattedAmount >= _vm.max},on:{"click":function($event){return _vm.changeAmount(1)}}},[_c('feather-icon',{staticClass:"icon",attrs:{"icon":"PlusIcon"}})],1)],1):_vm._e(),(_vm.editable)?_c('span',{staticClass:"currency small"},[_vm._v("Anzahl")]):_vm._e()]),(!_vm.hideInput)?_c('div',{staticClass:"d-flex align-items-center input-wrapper"},[_c('div',{staticClass:"ml-3 d-flex justify-content-center align-items-center flex-row",staticStyle:{"width":"70px"}},[_c('b-form-input',{staticClass:"text-input",attrs:{"min":"0","type":"number","size":"lg","disabled":true},model:{value:(_vm.formattedValue),callback:function ($$v) {_vm.formattedValue=$$v},expression:"formattedValue"}})],1),_c('span',{staticClass:"currency"},[_vm._v("€")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }