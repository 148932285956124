<template>
	<div class="finish-row d-flex flex-row">
		<div
			class="comment d-flex align-items-center justify-content-center"
			v-if="finish.comment"
			v-b-popover.hover.right="finish.comment"
		>
			<feather-icon icon="MessageCircleIcon" size="18" class="icon" />
		</div>
		<div class="data-col finish-info d-flex flex-row justify-content-between">
			<div class="finish-header d-flex align-items-center justify-content-center">
				<h5>Motiv {{ finishIdx + 1 }}</h5>
			</div>
			<div class="d-flex align-items-center">
				<div class="file d-flex align-items-center">
					<img :src="$getFileUrlById(finish.fileName)" />
				</div>
				<div class="info-col d-flex justify-content-center flex-column">
					<div class="info-item main-info">
						<span>{{ resolveLabel(finish.technique, 'technique') }}</span>
						<span>{{ resolveLabel(finish.side, 'side') }} / {{ resolveLabel(finish.position, 'position') }}</span>
						<span>{{ resolveLabel(variants[0].finishes[finishIdx].type, 'colorType') }}</span>
					</div>
				</div>
			</div>
			<div class="info-col d-flex justify-content-center flex-column">
				<div class="info-item">
					<span class="label">Datei:</span>
					{{ $getFileById(finish.fileName).width }}<span class="light"> x </span>{{ $getFileById(finish.fileName).height }}px
				</div>
				<div class="info-item">
					<span class="label text-capitalize">{{ resolveLabel(finish.sizeMeasure, 'sizeMeasure') }}:</span>
					{{ finish.size[finish.sizeMeasure] }}cm
				</div>
			</div>
			<!-- <div style="width: 100px"> -->
			<!-- <MockupDisplay
					v-if="showMockup"
					:variant="getConfigWithSingleConfig(config, finishIdx).variants[0]"
					:conf="getConfigWithSingleConfig(config, finishIdx)"
					:files="request.files"
					:customCurrentSide="finish.side"
				/> -->
			<!-- </div> -->
		</div>
		<SelectInput
			label="Siebeinrichtung"
			currency="Typ"
			:selection="
				(request.costs.productionCosts[configIdx] &&
					request.costs.productionCosts[configIdx].finishes[finishIdx].printScreenSetup.size) ||
				'<A4'
			"
			:value="
				(request.costs.productionCosts[configIdx] &&
					request.costs.productionCosts[configIdx].finishes[finishIdx].printScreenSetup.costs) ||
				0
			"
			:updateKey="`configurations.${configIdx}.finishes.${finishIdx}.printScreenSize`"
			:updateFn="updateCosts"
			:type="'printScreenSetup'"
			v-if="finish.technique === 'print'"
			class="data-col"
		/>
		<AmountInput
			label="Farbwechsel"
			currency="Anzahl"
			:amount="
				(request.costs.productionCosts[configIdx] &&
					request.costs.productionCosts[configIdx].finishes[finishIdx].colorChange.amount) ||
				0
			"
			:value="
				(request.costs.productionCosts[configIdx] &&
					request.costs.productionCosts[configIdx].finishes[finishIdx].colorChange.costs) ||
				0
			"
			:updateKey="`configurations.${configIdx}.finishes.${finishIdx}.colorChangeCount`"
			:updateFn="updateCosts"
			:editable="true"
			v-if="finish.technique === 'print'"
			class="data-col"
		/>
		<SelectInput
			label="Stickdatei"
			currency="Dateigröße"
			:selection="
				(request.costs.productionCosts[configIdx] && request.costs.productionCosts[configIdx].finishes[finishIdx].stickFile.size) ||
				'small'
			"
			:value="
				(request.costs.productionCosts[configIdx] &&
					request.costs.productionCosts[configIdx].finishes[finishIdx].stickFile.costs) ||
				0
			"
			:updateKey="`configurations.${configIdx}.finishes.${finishIdx}.stickFileSize`"
			:updateFn="updateCosts"
			:type="'stickFile'"
			v-if="finish.technique === 'stick'"
			class="data-col"
		/>
		<AmountInput
			label="Stichanzahl"
			currency="Anzahl"
			:amount="
				(request.costs.productionCosts[configIdx] &&
					request.costs.productionCosts[configIdx].finishes[finishIdx].stitchAmount.amount) ||
				2500
			"
			:value="
				(request.costs.productionCosts[configIdx] &&
					request.costs.productionCosts[configIdx].finishes[finishIdx].stitchAmount.costs) ||
				0
			"
			:updateKey="`configurations.${configIdx}.finishes.${finishIdx}.stitchAmount`"
			:updateFn="updateCosts"
			v-if="finish.technique === 'stick'"
			:editable="true"
			hideInput
			class="data-col"
		/>
	</div>
</template>

<script>
import { VBPopover } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import PriceInput from './PriceInput'
import AmountInput from './AmountInput'
import SelectInput from './SelectInput'

import FinishLabels from '@/assets/data/finishLabels.js'

export default {
	name: 'FinishRow',
	components: { PriceInput, AmountInput, SelectInput },
	props: {
		finish: { type: Object, required: true },
		finishIdx: { type: Number, required: true },
		variants: { type: Object, required: true },
		request: { type: Object, required: true },
		updateCosts: { type: Function, required: true },
		configIdx: { type: Number, required: true },
	},
	directives: {
		'b-popover': VBPopover,
		Ripple,
	},
	methods: {
		resolveLabel(value, type) {
			return FinishLabels[type][value] || 'n/a'
		},
	},
}
</script>

<style lang="sass" scoped>
.finish-row
	background-color: rgba(0,0,0,0.02)
	margin: 10px 0 10px 20px
	border-radius: 4px
	position: relative
	.comment
		position: absolute
		right: -26px
		top: 0
		bottom: 0
		width: 30px
		border-radius: 0 4px 4px 0
		background: rgba(255, 204, 0, 0.6)
		font-size: 12px
		cursor: pointer
	.data-col
		width: 350px
		height: 80px
		margin: 0 10px 0 0px
		padding-right: 10px
		background: none
		position: relative
		border-right: 1px solid rgba(0,0,0,0.1)
		border-radius: 0

		&:last-child
			border: none
			margin-right: 0

		&:first-child
			margin-left: 0

		.finish-header
			position: absolute
			top: -14px
			left: 10px
			h5
				margin: 0
				font-size: 12px
				font-weight: 700
				color: #5e5873
				.light
					font-weight: 400

		.file
			margin-right: 20px
			width: 50px
			img
				max-width: 50px
				max-height: 80%

		&.finish-info
			// margin-bottom: 4px
			padding: 8px 10px 8px 8px
			font-weight: 700
			font-size: 12px
			.info-col
				&:first-child
					margin-right: 20px
				.info-item
					padding: 0 0 2px
					&.main-info
						padding: 0
						span
							display: block
							margin: 4px 0
					.label
						font-weight: 300
					.light
						opacity: 0.7
</style>