<template>
	<div id="front-mockup" ref="svgContainer" :style="mockupWidth && { width: mockupWidth, height: mockupHeight }">
		<div
			v-for="(finish, idx) in filteredFinishes"
			:key="idx + '_filtered'"
			class="finish-wrapper"
			:style="isMounted && getFinishPosition(finish, 'finish_' + idx)"
			:ref="'finish_' + idx"
		>
			<img :src="$getFileUrlById(finish.fileName)" />
		</div>

		<DragFinish
			v-for="(finish, idx) in customFinishes"
			:key="idx + '_custom'"
			:finish="finish"
			:image="$getFileUrlById(finish.fileName)"
			:width="parseFloat(finish.size.width)"
			:height="finish.size.height"
			:initX="finish.coords ? finish.coords.x : 0"
			:initY="finish.coords ? finish.coords.y : 0"
			:mockupSide="mockupSide"
			:mockupType="mockupType"
			:style="cssVars"
			:currentStep="currentStep"
			:allFinishes="allFinishes"
			:files="files"
		/>

		<ShirtFront
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'shirt' || mockupType === 'shirt-round-neck'"
			:style="cssVars"
		/>

		<ShirtPocketFront
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'shirt-pocket'"
			:style="cssVars"
		/>

		<HoodieFront
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'hoodie'"
			:style="cssVars"
		/>

		<HoodieBagFront
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'hoodie-bag'"
			:style="cssVars"
		/>

		<HoodieZipperFront
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'hoodie-zipper'"
			:style="cssVars"
		/>

		<LongsleeveFront
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'longsleeve'"
			:style="cssVars"
		/>

		<LongsleevePocketFront
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'longsleeve-pocket'"
			:style="cssVars"
		/>

		<CrewneckFront
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'crewneck'"
			:style="cssVars"
		/>

		<PoloFront
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'polo'"
			:style="cssVars"
		/>

		<CapFront
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap' || mockupType === 'cap-strap'"
			:style="cssVars"
		/>

		<CapCurvedSnapbackFront
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-curved-snapback'"
			:style="cssVars"
		/>

		<CapDadFront
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-dad' || mockupType === 'cap-dad-belt-buckle'"
			:style="cssVars"
		/>

		<CapFlatSnapbackFront
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-flat-snapback'"
			:style="cssVars"
		/>

		<CapUnstructuredSnapbackFront
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-unstructured-snapback'"
			:style="cssVars"
		/>

		<OtherFront
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'custom'"
			:style="cssVars"
		/>
	</div>
</template>

<script>
import svgComponentData from './svgComponentData'
import ShirtFront from './textiles/shirt/ShirtFront'
import ShirtPocketFront from './textiles/shirt-pocket/ShirtPocketFront'
import HoodieFront from './textiles/hoodie/HoodieFront'
import HoodieBagFront from './textiles/hoodie-bag/HoodieBagFront'
import HoodieZipperFront from './textiles/hoodie-zipper/HoodieZipperFront'
import LongsleeveFront from './textiles/longsleeve/LongsleeveFront'
import LongsleevePocketFront from './textiles/longsleeve-pocket/LongsleevePocketFront'
import CrewneckFront from './textiles/crewneck/CrewneckFront'
import PoloFront from './textiles/polo/PoloFront'
import CapFront from './textiles/cap/CapFront'
import CapCurvedSnapbackFront from './textiles/cap-curved-snapback/CapCurvedSnapbackFront'
import CapDadFront from './textiles/cap-dad/CapDadFront'
import CapFlatSnapbackFront from './textiles/cap-flat-snapback/CapFlatSnapbackFront'
import CapUnstructuredSnapbackFront from './textiles/cap-unstructured-snapback/CapUnstructuredSnapbackFront'
import OtherFront from './textiles/other/OtherFront'

import DragFinish from './DragFinish'

const componentData = { ...svgComponentData }

componentData.name = 'SVGFront'
componentData.components = {
	ShirtFront,
	ShirtPocketFront,
	HoodieFront,
	HoodieBagFront,
	HoodieZipperFront,
	LongsleeveFront,
	LongsleevePocketFront,
	CrewneckFront,
	PoloFront,
	CapFront,
	CapCurvedSnapbackFront,
	CapDadFront,
	CapFlatSnapbackFront,
	CapUnstructuredSnapbackFront,
	OtherFront,
	DragFinish,
}

export default componentData
</script>

<style scoped lang="sass">
#front-mockup
	position: relative

.finish-wrapper
	position: absolute
	z-index: 10

	img
		width: 100%
		height: 100%

	&[data-position=""]
		display: none
</style>

<style lang="sass">
#front-mockup
	#PRINT-AREA
		stroke: var(--color) !important
	#POSITIONEN
		#center, #left, #right
			stroke: var(--color) !important
			opacity: var(--dotOpacity) !important
	#drag-finish
		.image-element
			outline-color: var(--color) !important
		.resize-btn
			background: var(--color) !important
			i
				color: var(--invertColor) !important
</style>
