<template>
	<div :class="[fullHeight && 'full-height']">
		<div :class="['wrapper', small && 'small', fullHeight && 'full-height']" v-if="!singleInput">
			<div class="label">
				{{ label }}
				<div class="info-icon-wrapper" v-if="tooltip">
					<feather-icon
						icon="HelpCircleIcon"
						size="18"
						class="position-absolute"
						:id="`tooltip-target-${tooltipKey + localValue}`"
					/>
					<b-tooltip :target="`tooltip-target-${tooltipKey + localValue}`" triggers="hover" position="left">
						<div class="price-input-tooltip-content" v-html="tooltip"></div>
					</b-tooltip>
				</div>
			</div>

			<div class="d-flex justify-content-end align-items-center flex-row cost-row">
				<div class="ml-3 d-flex justify-content-center align-items-center flex-row" style="width: 70px">
					<b-form-input
						type="number"
						min="0"
						:class="['text-input', !editable && 'disabled', localValue && 'edited']"
						v-model="formattedValue"
						size="lg"
						:disabled="!editable"
						@keydown="filterKey"
						@blur="updateValue"
						ref="input"
					></b-form-input>
				</div>
				<span class="currency">{{ currency }}</span>
			</div>
		</div>
		<b-form-input
			type="number"
			min="0"
			:class="['text-input', !editable && 'disabled', localValue && 'edited']"
			v-model="formattedValue"
			size="lg"
			:disabled="!editable"
			@keydown="filterKey"
			@blur="updateValue"
			ref="input"
			v-if="singleInput"
		></b-form-input>
	</div>
</template>

<script>
import { BButton, BModal, BRow, BCol, BFormInput, BTooltip, VBTooltip } from 'bootstrap-vue'

export default {
	name: 'PriceInput',
	components: { BButton, BModal, BRow, BCol, BFormInput, BTooltip },
	directives: {
		'b-tooltip': VBTooltip,
	},
	props: {
		label: { type: String, required: true },
		value: { type: Number, required: true },
		editable: { type: Boolean, default: false },
		valueKey: { type: String, required: false },
		updateKey: { type: String, required: false },
		updateFn: { type: Function, required: false },
		updated: { type: Object, required: false },
		small: { type: Boolean, default: false },
		currency: { type: String, required: false, default: '€' },
		singleInput: { type: Boolean, default: false },
		tooltip: { type: String, default: null },
		tooltipKey: { type: String, default: new Date().getTime().toString() },
		fullHeight: { type: Boolean, default: false },
	},
	computed: {
		formattedValue: {
			get() {
				const value = this.localValue === null ? parseFloat(Math.round(this.value * 100) / 100).toFixed(2) : this.localValue
				return this.currency === '%' ? parseFloat(value) : value
				// return this.localValue || parseFloat((Math.round(this.value * 100) / 100).toFixed(2))
			},
			set(amount) {
				this.localValue = amount
			},
		},
	},
	data() {
		return {
			localValue: null,
		}
	},
	methods: {
		updateValue() {
			const that = this
			const amount = this.localValue
			if (!!amount && !isNaN(amount) && this.updateFn) {
				console.log('updateValue: ', parseFloat(amount))
				this.updateFn(this.updateKey, parseFloat(amount), () => {
					that.localValue = null
				})
			}
		},
		filterKey(e) {
			// let previousValue = e.target.value
			let key = e.keyCode

			// console.log('Keycode: ', key)

			if (
				key === 8 || // backspace
				(key >= 37 && key <= 40) || // cursor keys
				(key >= 48 && key <= 57) || // 0..9
				(key >= 96 && key <= 105) || // numblock 0..9
				key === 188 || // comma
				key === 91 || // cmd (mac, for copy/paste)
				key === 17 || // control (windows, for copy/paste)
				key === 67 || // c (for copy)
				key === 86 // v (for paste)
			) {
				// all good
			} else if (key === 27) {
				// key: esc, reset value
				e.preventDefault()
				e.stopPropagation()
				this.localValue = null
			} else if (key === 13) {
				// key: enter, update
				this.$refs.input.blur()
			} else {
				// prevent input
				e.preventDefault()
			}

			return
		},
	},
}
</script>

<style lang="sass">
.price-input-tooltip-content
	text-align: left
	.tooltip-row
		margin-bottom: 8px
</style>

<style lang="sass" scoped>
.wrapper
	background: rgba(0,0,0,0.02)
	border-radius: 0.358rem
	padding: 8px
	margin-bottom: 4px

	&.small
		display: flex

.label
	font-size: 12px
	flex-grow: 1
	font-weight: 500
	margin-bottom: 8px
	position: relative
	.info-icon-wrapper
		position: absolute
		top: 0px
		right: -10px
		bottom: auto
.text-input
	background: #F1F1F1
	border: 2px solid #7367f0
	font-size: 14px
	color: #5E5873
	font-weight: 500
	text-align: center
	padding: 0
	height: 28px
	&.disabled
		border-color: transparent
	&.edited
		background-clip: content-box
		background-color: rgba(255, 204, 0, 0.6)
		border-color: rgba(255, 204, 0, 0.6)
		// background-color: rgba(234, 84, 85, 0.2)
		// border-color: rgba(234, 84, 85, 0.2)
.change-button
	z-index: 10
	position: relative
	padding: 6px
	margin-right: -16px
	border-radius: 4px 0 0 4px
	&:last-child
		border-radius: 0 4px 4px 0
		margin-right: 0
		margin-left: -16px
.currency
	opacity: 0.5
	font-size: 16px
	margin-left: 4px
	&.small
		margin-left: 0px
		font-size: 12px
</style>
