<template>
	<div id="right-mockup" ref="svgContainer" :style="mockupWidth && { width: mockupWidth, height: mockupHeight }">
		<div
			v-for="(finish, idx) in filteredFinishes"
			:key="idx + '_filtered'"
			class="finish-wrapper"
			:style="isMounted && getFinishPosition(finish, 'finish_' + idx)"
			:ref="'finish_' + idx"
		>
			<img :src="$getFileUrlById(finish.fileName)" />
		</div>

		<DragFinish
			v-for="(finish, idx) in customFinishes"
			:key="idx + '_custom'"
			:finish="finish"
			:image="$getFileUrlById(finish.fileName)"
			:width="parseFloat(finish.size.width)"
			:height="finish.size.height"
			:initX="finish.coords ? finish.coords.x : 0"
			:initY="finish.coords ? finish.coords.y : 0"
			:mockupSide="mockupSide"
			:mockupType="mockupType"
			:style="cssVars"
			:currentStep="currentStep"
			:allFinishes="allFinishes"
			:files="files"
		/>

		<ShirtRight
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'shirt' || mockupType === 'shirt-pocket' || mockupType === 'shirt-round-neck'"
			:style="cssVars"
		/>

		<HoodieRight
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'hoodie' || mockupType === 'hoodie-bag' || mockupType === 'hoodie-zipper'"
			:style="cssVars"
		/>

		<LongsleeveRight
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'longsleeve' || mockupType === 'longsleeve-pocket'"
			:style="cssVars"
		/>

		<CrewneckRight
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'crewneck'"
			:style="cssVars"
		/>

		<PoloRight
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'polo'"
			:style="cssVars"
		/>

		<CapRight
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap' || mockupType === 'cap-strap'"
			:style="cssVars"
		/>

		<CapCurvedSnapbackRight
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-curved-snapback'"
			:style="cssVars"
		/>

		<CapDadRight
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-dad' || mockupType === 'cap-dad-belt-buckle'"
			:style="cssVars"
		/>

		<CapFlatSnapbackRight
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-flat-snapback'"
			:style="cssVars"
		/>

		<CapUnstructuredSnapbackRight
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-unstructured-snapback'"
			:style="cssVars"
		/>
	</div>
</template>

<script>
import svgComponentData from './svgComponentData'
import ShirtRight from './textiles/shirt/ShirtRight'
import HoodieRight from './textiles/hoodie/HoodieRight'
import LongsleeveRight from './textiles/longsleeve/LongsleeveRight'
import CrewneckRight from './textiles/crewneck/CrewneckRight'
import PoloRight from './textiles/polo/PoloRight'
import CapRight from './textiles/cap/CapRight'
import CapCurvedSnapbackRight from './textiles/cap-curved-snapback/CapCurvedSnapbackRight'
import CapDadRight from './textiles/cap-dad/CapDadRight'
import CapFlatSnapbackRight from './textiles/cap-flat-snapback/CapFlatSnapbackRight'
import CapUnstructuredSnapbackRight from './textiles/cap-unstructured-snapback/CapUnstructuredSnapbackRight'

import DragFinish from './DragFinish'

const componentData = { ...svgComponentData }

componentData.name = 'SVGRight'
componentData.components = {
	ShirtRight,
	HoodieRight,
	LongsleeveRight,
	CrewneckRight,
	PoloRight,
	CapRight,
	DragFinish,
	CapDadRight,
	CapFlatSnapbackRight,
	CapCurvedSnapbackRight,
	CapUnstructuredSnapbackRight,
}

export default componentData
</script>

<style scoped lang="sass">
#right-mockup
	position: relative

.finish-wrapper
	position: absolute
	z-index: 10

	img
		width: 100%
		height: 100%

	&[data-position=""]
		display: none
</style>

<style lang="sass">
#right-mockup
	#PRINT-AREA
		stroke: var(--color) !important
	#POSITIONEN
		#center, #left, #right
			stroke: var(--color) !important
			opacity: var(--dotOpacity) !important
	#drag-finish
		.image-element
			outline-color: var(--color) !important
		.resize-btn
			background: var(--color) !important
			i
				color: var(--invertColor) !important
</style>